import { Tabs } from "../../../../../components/Tabs"
import { Button } from "../../../../../components/Button"
import { Input } from "../../../../../components/Input"
import { Table } from "../../../../../components/Table"

const FundTransfer = ({ handleClose, selected = {} }) => {
  function renderReconciliation() {
    return (
      <>
        <form className="card-body">
          <Input
            label="Transaction Date"
            value={
              selected &&
              selected.reconciliation &&
              selected.reconciliation.transactionDate
            }
            disabled
          />
          <Input
            label="Date Created"
            value={
              selected &&
              selected.reconciliation &&
              selected.reconciliation.dateCreated
            }
            disabled
          />
          <Input
            label="Date Modified"
            value={
              selected &&
              selected.reconciliation &&
              selected.reconciliation.dateModified
            }
            disabled
          />
        </form>
        <div className="card-footer d-flex justify-content-end">
          <Button
            text="Close"
            onPress={handleClose}
            className="btn-secondary"
          />
        </div>
      </>
    )
  }
  function renderBatchSettlements() {
    return (
      <>
        <form className="card-body">
          <Input
            label="Batch Number"
            value={
              selected &&
              selected.settlementBatch &&
              selected.settlementBatch.batchNumber
            }
            disabled
          />
          <Input
            label="Date Created"
            value={
              selected &&
              selected.settlementBatch &&
              selected.settlementBatch.dateCreated
            }
            disabled
          />
          <Input
            label="Date Modified"
            value={
              selected &&
              selected.settlementBatch &&
              selected.settlementBatch.dateModified
            }
            disabled
          />
        </form>
        <div className="card-footer d-flex justify-content-end">
          <Button
            text="Close"
            onPress={handleClose}
            className="btn-secondary"
          />
        </div>
      </>
    )
  }

  function renderItems() {
    return (
      <>
        <div className="card-body">
          <Table
            headers={[
              { title: "Vehicle", key: "vehicleNo" },
              { title: "Amount", key: "amount" },
              { title: "Client", key: "paymentServiceAccount.name" },
              {
                title: "Account Type",
                key: "paymentServiceAccount.accountType",
              },
              {
                title: "Service Number",
                key: "paymentServiceAccount.serviceNumber",
              },
              { title: "Date Created", key: "dateCreated" },
              { title: "Date Modified", key: "dateModified" },
            ]}
            data={(selected && selected.items) || []}
          />
        </div>
        <div className="card-footer d-flex justify-content-end">
          <Button
            text="Close"
            onPress={handleClose}
            className="btn-secondary"
          />
        </div>
      </>
    )
  }

  function renderActivities() {
    return (
      <>
        <div className="card-body">
          <Table
            headers={[
              { title: "Description", key: "description" },
              { title: "Date Created", key: "dateCreated" },
            ]}
            data={(selected && selected.activities) || []}
          />
        </div>
        <div className="card-footer d-flex justify-content-end">
          <Button
            text="Close"
            onPress={handleClose}
            className="btn-secondary"
          />
        </div>
      </>
    )
  }

  const options = [
    {
      title: "Reconciliation",
      data: renderReconciliation(),
    },
    {
      title: "Batch Settlement",
      data: renderBatchSettlements(),
    },
    {
      title: "Activities",
      data: renderActivities(),
    },
    {
      title: "Items",
      data: renderItems(),
    },
  ]
  return (
    <div className="card">
      <Tabs options={options} />
    </div>
  )
}

export default FundTransfer
