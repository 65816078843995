import React, { useState } from "react"
import useAxios from "../../../../hooks/useAxios"
import useHandleFormValidate from "../../../../hooks/useHandleFormValidate"
import { useNavigate } from "react-router-dom"
import { StepperItem, Steppers } from "../../../../components/Steppers"
import ContactPerson from "../AccountForms/ContactPerson"
import BankAccount from "../AccountForms/BankAccount"
import { RECONCILIATION_ROOT_API } from "../../../../constants/api"
import toastMessage from "../../../../utils/toastMessage"

const IDENTIFICATIONS = [
	{ label: "NationalId", value: "NationalId" },
	{ label: "PassportNo", value: "PassportNo" },
]

const Add = ({ selected, setSelected }) => {
	const [step, setStep] = useState(0)

	const axios = useAxios()
	const validate = useHandleFormValidate()
	const navigate = useNavigate()

	const [account, setAccount] = useState({
		bankAccountName: "",
		bankAccountNo: "",
		bankAccountBalance: "",
		bankAccountStatus: {},
		bankName: "",
		bankCode: "",
	})
	const [accountError, setAccountError] = useState({})

	const [contactPerson, setContactPerson] = useState({
		name: "",
		phoneNumber: "254789765435",
		email: "johndoh@email.com",
		identificationType: IDENTIFICATIONS[0],
		identificationValue: "",
	})
	const [contactPersonError, setContactPersonError] = useState({})

	const [submitting, setSubmitting] = useState(false)
	const [submit, setSubmit] = useState(true)

	const handleNext = async (current) => {}

	const handleSubmit = async (e) => {
		e && e.preventDefault()
		if (step === 0) {
			submitContactPerson()
		}
		if (step === 1) {
			submitAccount()
		}
	}

	const submitContactPerson = async (e) => {
		const { error, valid } = await validate(contactPerson)
		if (error) setContactPersonError(error)
		if (!valid) return

		setSubmitting(true)
		let data = {
			name: contactPerson.name,
			phoneNumber: contactPerson.phoneNumber,
			email: contactPerson.email,
			identificationType: contactPerson.identificationType.value,
			identificationValue: contactPerson.identificationValue,
		}
		// if(selected&&selected.publicId){
		// 	data.publicId = selected.publicId
		// }

		const { err, res, status } = await axios({
			method: "POST",
			// method: selected && selected.publicId ? "PUT" : "POST",
			url: `${RECONCILIATION_ROOT_API}bankAccountContactPersons`,
			headers: null,
			data,
		})

		if (err) {
			if (err.messages) {
				err.messages.forEach((err) => {
					toastMessage("error", err)
				})
			} else {
				toastMessage("error", err)
			}
			setSubmitting(false)
		}
		if (res || (status && status === 200)) {
			toastMessage(
				"success",
				`Contact person details ${
					contactPerson.publicId ? "updated" : "added"
				} successfully.`
			)
			setContactPerson(res)
			setSubmitting(false)
			setStep(1)
		}
	}
	const submitAccount = async (e) => {
		const { error, valid } = await validate(account)
		if (error) setAccountError(error)
		if (!valid) return

		setSubmitting(true)

		let data = {
			bankAccountContactPerson: contactPerson.publicId,
			bankAccountName: account.bankAccountName,
			bankName: account.bankName,
			bankCode: account.bankCode && account.bankCode.value,
			bankAccountNo: account.bankAccountNo,
			bankAccountBalance: Number(account.bankAccountBalance),
			bankAccountStatus:
				account.bankAccountStatus && account.bankAccountStatus.value,
		}
		if (selected && selected.publicId) {
			data.publicId = selected.publicId
		}
		const { err, res, status } = await axios({
			method: selected && selected.publicId ? "PUT" : "POST",
			url: `${RECONCILIATION_ROOT_API}bankAccounts`,
			headers: null, //contactPerson
			data,
		})

		if (err) {
			if (err.messages) {
				err.messages.forEach((err) => {
					toastMessage("error", err)
				})
			} else {
				toastMessage("error", err)
			}
			setSubmitting(false)
		}
		if (res || (status && status === 200)) {
			toastMessage(
				"success",
				`Account details ${
					account.publicId ? "updated" : "added"
				} successfully.`
			)
			setSubmitting(false)
			navigate("/dashboard/bank_accounts/accounts_details")
		}
	}

	return (
		<div className="card">
			<div className="card-body">
				<Steppers
					currentStep={step}
					close={() => navigate("/dashboard/bank_accounts/accounts_details")}
				>
					<span>Contact Person</span>
					<span>Account details</span>
				</Steppers>
				<StepperItem
					handleNext={handleNext}
					stepsCount={2}
					submitting={submitting}
					handleSubmit={handleSubmit}
					setSubmit={submit}
					handleSetSubmit={setSubmit}
					step={step}
					setStep={setStep}
				>
					{step === 0 ? (
						<ContactPerson
							contactPersonError={contactPersonError}
							contactPerson={contactPerson}
							setContactPerson={setContactPerson}
							IDENTIFICATIONS={IDENTIFICATIONS}
						/>
					) : (
						<BankAccount
							account={account}
							setAccount={setAccount}
							accountError={accountError}
						/>
					)}
				</StepperItem>
			</div>
		</div>
	)
}

export default Add
