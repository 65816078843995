import React from "react";
import { Link } from "react-router-dom";
import { sidebarMenus } from "../../constants/sidebar";
import Logo from "../../assets/logo.png";
import MobitillLogo from "../../assets/mobitill_logo.png";
import "./styles.css";
import { getStorage } from "../../utils/storage";
import { connect } from "react-redux";
import { onToggle } from "../../actions/ToggleSidebar";

class Sidebar extends React.Component {
  state = {
    sidebar_menus: sidebarMenus.slice(0),
  };

  componentDidMount = async () => {
    const user = await getStorage();
    if (user.access_role) {
      const { denied_menu } = user.access_role;

      this.handleAccessMenu(denied_menu);
    }

    document.addEventListener(
      "click",
      this.handleClickOutside.bind(this),
      true
    );
  };

  handleAccessMenu(denied_menu) {
    let group_menus = {};

    for (let i = 0; i < sidebarMenus.length; i++) {
      if (!group_menus[sidebarMenus[i].group]) {
        group_menus[sidebarMenus[i].group] = [];
      }

      if (sidebarMenus[i].items) {
        group_menus[sidebarMenus[i].group].push(...sidebarMenus[i].items);
      } else {
        group_menus[sidebarMenus[i].group] = sidebarMenus[i];
      }
    }

    for (let i = 0; i < denied_menu.length; i++) {
      let g = denied_menu[i].value.split("/")[0];

      let name = denied_menu[i].value.split("/")[1];

      let items = group_menus[g];

      for (let sub = 0; sub < items.length; sub++) {
        if (items[sub].name === name) {
          items.splice(sub, 1);
        }
      }

      if (items.length === 0) {
        delete group_menus[g];
      } else {
        group_menus[g].items = items;
      }
    }

    let allowed_menus = [],
      sidebar_menus = [];

    let keys = Object.keys(group_menus);

    for (let i = 0; i < keys.length; i++) {
      allowed_menus.push(keys[i]);
    }

    for (let i = 0; i < sidebarMenus.length; i++) {
      if (allowed_menus.includes(sidebarMenus[i].group)) {
        if (sidebarMenus[i].items) {
          sidebarMenus[i].items = group_menus[sidebarMenus[i].group];
        }
        sidebar_menus.push(sidebarMenus[i]);
      }
    }

    this.setState({
      sidebar_menus,
    });
  }

  toggleMenu(index) {
    let menu = this.state.sidebar_menus[index];

    menu.show = !menu.show;

    this.state.sidebar_menus[index] = menu;

    this.setState({
      sidebar_menus: this.state.sidebar_menus,
    });
  }

  toggleSidebar() {
    this.props.dispatch(onToggle(false));
  }

  handleClickOutside(event) {
    const { innerWidth } = window;

    if (
      this.refs.sidebar &&
      !this.refs.sidebar.contains(event.target) &&
      innerWidth < 750
    ) {
      this.props.dispatch(onToggle(false));
    }
  }

  render() {
    const { location, show_sidebar } = this.props;

    let pathname = location && location.pathname ? location.pathname : "";

    return (
      <div
        id="sidebar dashboard-sidebar"
        className={`sidebar ${show_sidebar ? "show" : "hide"}`}
        ref="sidebar"
      >
        <div className="header">
          <div className="logo">
            <img src={MobitillLogo} />
          </div>
          <div className="mobile-close" onClick={this.toggleSidebar.bind(this)}>
            <i className="bx bxs-arrow-from-right"></i>
          </div>
        </div>

        <div className="middle">
          <ul>
            {this.state.sidebar_menus.map((menu, m) => {
              return (
                <li key={m}>
                  {menu.title !== "" && menu.items ? (
                    <div
                      className="menu-item-header"
                      onClick={menu.items && this.toggleMenu.bind(this, m)}
                    >
                      <h1
                        className={
                          pathname.includes(
                            menu.group
                              .toLowerCase()
                              .replaceAll(" ", "_")
                              // .replace(" ", "_")
                          )
                            ? "sidebar-text-title-active"
                            : "sidebar-text-title"
                        }
                      >
                        <div
                          className={
                            pathname.includes(
                              menu.group.toLowerCase().replace(" ", "_")
                            )
                              ? "main-sidebar-item-icon main-sidebar-item-icon-active"
                              : "main-sidebar-item-icon"
                          }
                        >
                          <i className={`bx ${menu.icon} left-icon`} />
                        </div>
                        {menu.group}
                      </h1>
                      {menu.items && (
                        <div className="menu-header-right-icon">
                          <i className="bx bx-chevron-down" />
                        </div>
                      )}
                    </div>
                  ) : (
                    <Link className="menu-item-header" to={menu.route}>
                      <h1
                        className={
                          pathname.includes(
                            menu.group
                              .toLowerCase()
                              .replace(" ", "_")
                              .replace(" ", "_")
                          )
                            ? "sidebar-text-title-active"
                            : "sidebar-text-title"
                        }
                      >
                        <div className="main-sidebar-item-icon">
                          <i className={`bx ${menu.icon} left-icon`} />
                        </div>
                        {menu.group}
                      </h1>
                      {menu.items && (
                        <div className="menu-header-right-icon">
                          <i className="bx bx-chevron-down" />
                        </div>
                      )}
                    </Link>
                  )}

                  <div className={`sub-menus ${menu.show ? "show" : ""}`}>
                    {menu.items &&
                      menu.items.map((item, i) => {
                        let split_path = pathname.split("/");
                        let last_route = split_path[split_path.length - 1];
                        let group = menu.group
                          .toLowerCase()
                          .replace(" ", "_")
                          .replace(" ", "_");
                        let route_name = item.name
                          .replace(" ", "_")
                          .replace(" ", "_")
                          .toLowerCase();
                        return (
                          <Link
                            key={i}
                            to={"/dashboard/" + group + "/" + route_name}
                            title={item.name}
                            className={
                              pathname.includes(route_name) ? "active" : ""
                            }
                          >
                            <div className="sidebar-item-icon">
                              <i className={`bx ${item.icon}`}></i>
                            </div>
                            <span style={{ marginLeft: "1rem" }}>
                              {item.name}
                            </span>
                          </Link>
                        );
                      })}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { show_sidebar } = state.ToggleSidebar;

  return { show_sidebar };
};

export default connect(mapStateToProps)(Sidebar);
