import { AsyncSelect, Input, Select } from "../../../../../components/Input"
import { Button } from "../../../../../components/Button"
import useApp from "../../../../../hooks/useApp"
import { useEffect, useState } from "react"
import { RECONCILIATION_ROOT_API } from "../../../../../constants/api"
import useAxios from "../../../../../hooks/useAxios"

const StatementFilters = ({ getData, setFilterItems }) => {
	const { setShowFilter } = useApp()
	const [items, setItems] = useState({
		accountNumber: "",
		transactionType: "",
		transactionReference: "",
		creditAmount: "",
	})
	const axios = useAxios()

	const handleInput = (e) => {
		const { name, value } = e.target
		setItems((prev) => ({
			...prev,
			[name]: value,
		}))
	}

	const applyFilters = async () => {
		await setFilterItems(items)
		getData({ ...items })
	}

	return (
		<>
			<div className="filter-top">
				<div className="filter-top-items">
					<h1>Filters</h1>
					<Button
						onPress={() => setShowFilter(false)}
						className=""
						icon="bx bx-x"
					/>
				</div>
			</div>
			<div className="filter-center">
				<Input
					label="Account Number"
					name="accountNumber"
					value={items.accountNumber}
					onChange={handleInput}
				/>
				<Input
					label="Transaction Type"
					name="transactionType"
					value={items.transactionType}
					onChange={handleInput}
				/>
				<Input
					label="Transaction Reference"
					name="transactionReference"
					value={items.transactionReference}
					onChange={handleInput}
				/>
				<Input
					label="Credit Amount"
					name="creditAmount"
					value={items.creditAmount}
					onChange={handleInput}
				/>
			</div>

			<div className="filter-bottom">
				<div className="filter-bottom-items ">
					<Button
						onPress={() => (
							getData({
								accountNumber: "",
								transactionType: "",
								transactionReference: "",
								creditAmount: "",
							}),
							setShowFilter(false)
						)}
						className="btn-secondary"
						text="Reset"
					/>
					<Button
						onPress={() => (applyFilters(), setShowFilter(false))}
						className="btn-primary"
						text="Apply"
					/>
				</div>
			</div>
		</>
	)
}

export default StatementFilters
