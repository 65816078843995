import { useEffect, useState } from "react"
import { Table } from "../../../../../components/Table"
import toastMessage from "../../../../../utils/toastMessage"
import useAxios from "../../../../../hooks/useAxios"
import { RECONCILIATION_ROOT_API } from "../../../../../constants/api"
import Filters from "./Filters"
import useApp from "../../../../../hooks/useApp"

const MpesaStatements = ({ reconciliationPublicId }) => {
	const [loading, setLoading] = useState(true)
	const [data, setData] = useState([])
	const [pagination, setPagination] = useState({})
	const axios = useAxios()
	const { setShowFilter, setFilters } = useApp()
	const [filterItems, setFilterItems] = useState({
		accountNo: "",
		paymentServiceAccountPublicId: "",
		shortCode: "",
		receiptNumber: "",
		transactionStatus: "",
		amountPaid: "",
	})

	useEffect(() => {
		if (reconciliationPublicId) {
			getData()
		} else {
			setLoading(false)
			toastMessage(
				"error",
				"A reconciliation is required to view its transaction statements."
			)
		}
	}, [])

	const handleShowFilters = async () => {
		await setFilters(
			<Filters getData={getData} setFilterItems={setFilterItems} />
		)
		setShowFilter(true)
	}

	const getData = async (params = {}) => {
		setLoading(true)
		let filters = { ...filterItems }
		if (filterItems.paymentServiceAccountPublicId) {
			filters.paymentServiceAccountPublicId =
				filterItems.paymentServiceAccountPublicId.value
		}
		const { err, res } = await axios({
			method: "GET",
			url: `${RECONCILIATION_ROOT_API}reconciliations/${reconciliationPublicId}/mpesaStatements`,
			params: {
				reconciliationPublicId,
				...filters,
				...params,
			},
			headers: null,
		})
		if (err) {
			setLoading(false)
			return
		}
		if (res) {
			setLoading(false)
			const { content, number, numberOfElements, totalElements, totalPages } =
				res
			setData(content)
			const pagination = {
				pageNumber: number + 1,
				pageCount: numberOfElements,
				totalCount: totalElements,
				pageCount: totalPages,
			}
			setPagination(pagination)
		}
	}

	const handlePagination = async (page) => {
		if (!page) return
		getData({ page: page - 1 })
	}

	const headers = [
		{ title: "Service Account", key: "accountName" },
		{ title: "Shot Code", key: "shortCode" },
		{ title: "Account Number", key: "accountNo" },
		{ title: "From Date", key: "timePeriodFrom" },
		{ title: "To Date", key: "timePeriodTo" },
		{ title: "Receipt No", key: "receiptNumber" },
		{ title: "Initiation Time", key: "initiationTime" },
		{ title: "Completion Time", key: "completionTime" },
		{ title: "Status", key: "transactionStatus" },
		{ title: "Amount Paid", key: "amountPaid" },
		{ title: "Amount Withdrawn", key: "amountWithdrawn" },
		{ title: "Reason", key: "reasonType" },
		{ title: "Linked Transaction ID", key: "linkedTransactionId" },
		{ title: "Details", key: "details" },
	]
	return (
		<div className="card">
			<div className="card-body">
				<Table
					data={data}
					handlePagination={handlePagination}
					totalPageCount={pagination.pageCount}
					page={pagination.pageNumber}
					headers={headers}
					isLoading={loading}
					showFilters
					handleFilter={handleShowFilters}
				/>
			</div>
		</div>
	)
}

export default MpesaStatements
