import { useEffect, useState } from "react"
import { Table } from "../../../../components/Table"
import { RECONCILIATION_ROOT_API } from "../../../../constants/api"
import useAxios from "../../../../hooks/useAxios"
import { useNavigate } from "react-router-dom"
import toastMessage from "../../../../utils/toastMessage"
import { Modal } from "../../../../components/Modal"
import ContactPerson from "../AccountForms/ContactPerson"

const ViewAll = () => {
	const [data, setData] = useState([])
	const [pagination, setPagination] = useState({})
	const [loading, setLoading] = useState(true)
	const [showEdit, setShowEdit] = useState(false)
	const [selected, setSelected] = useState()

	const axios = useAxios()
	const navigate = useNavigate()

	useEffect(() => {
		getData()
	}, [])

	const getData = async (params = {}) => {
		setLoading(true)
		const { err, res } = await axios({
			method: "GET",
			url: `${RECONCILIATION_ROOT_API}bankAccountContactPersons`,
			params: {
				...params,
			},
			headers: null,
		})
		if (err) {
			if (err.messages) {
				toastMessage("error", err.messages[0])
			}
			console.log({ err })
			setLoading(false)
			return
		}
		if (res) {
			setLoading(false)

			console.log("BA: ", res)

			const { content, number, numberOfElements, totalElements, totalPages } =
				res
			const pagination = {
				pageNumber: number + 1,
				pageCount: numberOfElements,
				totalCount: totalElements,
				pageCount: totalPages,
			}
			setPagination(pagination)
			setData(content)
		}
	}

	const handlePagination = async (page) => {
		if (!page) return
		getData({ page: page - 1 })
	}

	const headers = [
		{ title: "Name", key: "name" },
		{ title: "Phone Number", key: "phoneNumber" },
		{ title: "Email", key: "email" },
		{ title: "Identification Type", key: "identificationType" },
		{ title: "Identification Number", key: "identificationValue" },
		{ title: "Date Created", key: "dateCreated" },
		{ title: "Date Updated", key: "dateModified" },
		{ title: "Actions", key: "action" },
	]
	return (
		<>
			<div className="card">
				<div className="card-body">
					<Table
						headers={headers}
						data={data}
						isLoading={loading}
						showAdd
						addButtonText="Add Account"
						handlePagination={handlePagination}
						totalPageCount={pagination.pageCount}
						page={pagination.pageNumber}
						actions={[
							{
								name: "Edit",
								onPress: async (row) => (
									await setSelected(row), setShowEdit(true)
								),
							},
							{
								name: "Accounts",
								onPress: (row) =>
									navigate(`accounts/${row.name}`, {
										state: { selected: row },
									}),
							},
						]}
					/>
				</div>
			</div>

			<Modal
				show={showEdit}
				title="Update Contact Person"
				handleClose={() => (setSelected(), setShowEdit(false))}
			>
				<ContactPerson
					getData={getData}
					selected={selected}
					handleClose={() => (setSelected(), setShowEdit(false))}
				/>
			</Modal>
		</>
	)
}

export default ViewAll
