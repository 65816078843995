import { useState } from "react"
import { Button } from "../../../../../components/Button"
import { Input, Select } from "../../../../../components/Input"
import useAxios from "../../../../../hooks/useAxios"
import { RECONCILIATION_ROOT_API } from "../../../../../constants/api"
import toastMessage from "../../../../../utils/toastMessage"

const BankStatemnt = ({
	getData,
	selected,
	handleClose,
	reconciliationPublicId,
}) => {
	const [action, setAction] = useState({})
	const [error, setError] = useState({})
	const [loading, setLoading] = useState(false)
	const axios = useAxios()

	const handleSubmit = async () => {
		if (!action || !action.value) {
			setError({ action: "Select an action to proceed." })
			return
		}
		setLoading(true)
		const { err, res } = await axios({
			method: "PATCH",
			url: `${RECONCILIATION_ROOT_API}reconciliations/${reconciliationPublicId}/bankStatements/${selected.publicId}/actions`,
			data: {
				actionType: action.value,
			},
			params: {
				reconciliationPublicId,
				bankStatementPublicId: selected.publicId,
			},
			headers: null,
		})
		if (err) {
			if (err.messages) {
				toastMessage("error", err.messages[0])
			}
			setLoading(false)
			return
		}
		if (res) {
			toastMessage("success", "Bank statement approved successfully.")
			setLoading(false)
			getData()
			handleClose()
		}
	}
	return (
		<div className="card">
			<div className="card-body">
				<Input label="Account Name" disabled value={selected.accountName} />
				<Input label="Account Number" disabled value={selected.accountNumber} />
				<Input label="Credit Amount" disabled value={selected.creditAmount} />
				<Select
					required
					label="Action "
					value={action}
					onChange={(value) => (setAction(value), delete error.action)}
					error={error.action}
					options={[
						{ label: "Approve", value: "Approve" },
						{ label: "Reject", value: "Reject" },
					]}
				/>
			</div>
			<div className="card-footer d-flex justify-content-end gap-3">
				<Button
					onPress={() => handleClose()}
					className="btn-secondary"
					text="Cancel"
				/>
				<Button
					isSubmitting={loading}
					onPress={handleSubmit}
					className="btn-primary"
					text="Submit"
				/>
			</div>
		</div>
	)
}

export default BankStatemnt
