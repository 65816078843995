import axios from "axios"
import { getStorage } from "../utils/storage"
import toastMessage from "../utils/toastMessage"

const useAxios = () => {
	return async (obj) => {
		const { token } = await getStorage()
		const {
			headers = {
				Authorization: `Bearer ${token}`,
			},
			method,
			data = {},
			url,
			params = {},
		} = obj
		try {
			let res = await axios({
				headers: headers,
				method: method,
				url: `${url}`,
				data: data,
				params: params,
			})
			return { res: res.data, status: res.status }
		} catch (e) {
			if (e.message === "Network Error") {
				toastMessage("error", "Network connection failed")
				return { err: e.message }
			}
			if (e.response && e.response.data && e.response.data.message) {
				toastMessage("error", e.response.data.message)
			}
			if (e&&e.response&&e.response.data) {
				return { err: e.response.data }
			}
			if (e&&e.response) {
				return { err: e.response }
			}
			return { err: e }
		}
	}
}

export default useAxios
