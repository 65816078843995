import { useEffect, useState } from "react"
import { Table } from "../../../../../components/Table"
import useAxios from "../../../../../hooks/useAxios"
import { RECONCILIATION_ROOT_API } from "../../../../../constants/api"
import { Modal } from "../../../../../components/Modal"
import Exception from "./Exception"

const ExceptionReports = ({
	reconciliationPublicId,
	setExeActive,
	setViewExeReportDispute,
}) => {
	const [loading, setLoading] = useState(true)
	const [data, setData] = useState([])
	const [show, setShow] = useState(false)
	const [selected, setSelected] = useState({})
	const [pagination, setPagination] = useState({})
	const axios = useAxios()

	useEffect(() => {
		if (reconciliationPublicId) {
			getData()
		} else {
			setLoading(false)
			// toastMessage("error", "A reconciliation is required to view its transaction statements.")
		}
	}, [])

	const getData = async (params = {}) => {
		setLoading(true)
		const { err, res } = await axios({
			method: "GET",
			url: `${RECONCILIATION_ROOT_API}reconciliations/${reconciliationPublicId}/exceptionReport`,
			params: {
				reconciliationPublicId,
				...params,
			},
			headers: null,
		})
		if (err) {
			setLoading(false)
			return
		}
		if (res) {
			setLoading(false)
			const { content, number, numberOfElements, totalElements, totalPages } =
				res
			setData(content)
			const pagination = {
				pageNumber: number + 1,
				pageCount: numberOfElements,
				totalCount: totalElements,
				pageCount: totalPages,
			}
			setPagination(pagination)
		}
	}

	const handleDispute = async (item) => {
		await setViewExeReportDispute(item)
		await setExeActive("disputes")
		setExeActive()
	}

	const handlePagination = async (page) => {
		if (!page) return
		getData({ page: page - 1 })
	}

	const headers = [
		{ title: "Account Number", key: "accountNo" },
		{ title: "Service Account", key: "paymentServiceAccount.name" },
		{ title: "Account Type", key: "paymentServiceAccount.accountType" },
		{ title: "Service No", key: "paymentServiceAccount.serviceNumber" },
		{ title: "Payment Mode", key: "paymentMode" },
		{ title: "Payment Result", key: "paymentResult" },
		{ title: "Amount", key: "totalAmount" },
		{ title: "Confirmed Amount", key: "totalConfirmedAmount" },
		{ title: "Reversed Amount", key: "totalReversedAmount" },
		{ title: "Created At", key: "date" },
		{ title: "Actions", key: "action" },
	]
	return (
		<>
			<div className="card">
				<div className="card-body">
					<Table
						data={data}
						headers={headers}
						handlePagination={handlePagination}
						totalPageCount={pagination.pageCount}
						page={pagination.pageNumber}
						isLoading={loading}
						actions={[
							{
								name: "Settle",
								onPress: (item) => (setSelected(item), setShow(true)),
							},
							{
								name: "Disputes",
								onPress: handleDispute,
							},
						]}
					/>
				</div>
			</div>
			<Modal
				title="Settle Exception"
				show={show}
				handleClose={() => (setSelected({}), setShow(false))}
			>
				<Exception
					getData={getData}
					reconciliationPublicId={reconciliationPublicId}
					selected={selected}
					handleClose={() => (setSelected({}), setShow(false))}
				/>
			</Modal>
		</>
	)
}

export default ExceptionReports
