import { Outlet, Route, Routes, useSearchParams } from "react-router-dom"
import DashboardLayout from "./DashboardLayout"
import AppContext from "../../context/AppContext"

//All dashboard routes
import HomePage from "./Home"
import Reconciliation from "./Reconciliation"
import ServiceAccounts from "./ServiceAccounts"
import BankAccounts from "./BankAccounts"
import { FilterContainer } from "../../components/Filter"
import { useState } from "react"

const Dashboard = () => {
	const [showFilter, setShowFilter] = useState(false)
	const [filters, setFilters] = useState(null)
	const [resetFilters, setResetFilters] = useState(null)
	const [applyFilters, setApplyFilters] = useState(null)

	return (
		<AppContext.Provider
			value={{
				showFilter,
				setShowFilter,
				filters,
				setFilters,
			}}
		>
			<DashboardLayout>
				<Routes>
					<Route path="" element={<HomePage />} />
					<Route path="home" element={<HomePage />} />
					<Route path="reconciliation/*" element={<Reconciliation />} />
					<Route path="service_accounts/*" element={<ServiceAccounts />} />
					<Route path="bank_accounts/*" element={<BankAccounts />} />
				</Routes>
				<Outlet />
			</DashboardLayout>
			{showFilter && <FilterContainer />}
		</AppContext.Provider>
	)
}

export default Dashboard
