import { useEffect, useState } from "react"
import { Select } from "../../../../components/Input"
import useAxios from "../../../../hooks/useAxios"
import { OLD_EPESI_TOKEN, OLD_EPESI_API } from "../../../../constants/api"
import toastMessage from "../../../../utils/toastMessage"

const Organization = ({ organization, setOrganization, setDetails, orgErr }) => {
	const [orgs, setOrgs] = useState([])
	const [apps, setApps] = useState([])

	const [loadingOrg, setLoadingOrg] = useState(true)
	const [loadingApp, setLoadingApp] = useState(false)

	const axios = useAxios()

	useEffect(() => {
		getData()
	}, [])

	const getData = async () => {
		const { err, res, status } = await axios({
			method: "GET",
			url: `${OLD_EPESI_API}/organizations`,
			headers: {
				Authorization: `Bearer ${OLD_EPESI_TOKEN}`,
			},
		})
		if (err) {
			setLoadingOrg(false)
			return toastMessage(
				"error",
				err.errors
					? err.errors && err.errors[0]
					: "Failed to fetch the organizations. Please try again."
			)
		}
		if (res) {
			if (!res.data || !Array.isArray(res.data)) return
			let data = await res.data.map((entry) => {
				let item = { ...entry }
				item.label = entry.name
				item.value = entry._id
				return item
			})
			setOrgs(data)
			setLoadingOrg(false)
		}
	}

	const getApps = async (org) => {
		if (!org || !org.value) {
			return toastMessage(
				"error",
				"Select an orginization above before choosing an application."
			)
		}
		setLoadingApp(true)
		const { err, res, status } = await axios({
			method: "GET",
			url: `${OLD_EPESI_API}/apps`,
			headers: {
				Authorization: `Bearer ${OLD_EPESI_TOKEN}`,
			},
			params: {
				"0[]": "orgid",
				"1[]": org.value,
			},
		})

		if (err) {
			setLoadingOrg(false)
			return toastMessage(
				"error",
				err.errors
					? err.errors && err.errors[0]
					: "Failed to fetch the organization applications. Please try again."
			)
		}
		if (res) {
            if (!res.data || !Array.isArray(res.data)) return
			let data = await res.data.map((entry) => {
                let item = { ...entry }
				item.label = entry.name
				item.value = entry._id
				return item
			})
            setApps(data)
            setLoadingApp(false)
		}
	}

	const handleSelect = (value, { name }) => {
		if (orgErr[name]) {
			delete orgErr[name]
		}
		setOrganization((prev) => ({
			...prev,
			[name]: value,
		}))
		if ((name = "org")) {
			getApps(value)
			setDetails(prev=>({
				...prev,
				name: value.label
			}))
		}
	}
	return (
		<div className="card-body ">
			<div className="d-flex w-100 flex-column align-items-center">
				<div className="col-md-9 col-lg-6 row">
					<div className="col-sm-12">
						<h3 className="mb-3">Select Organization</h3>
						<Select
							label="Organization:"
							required
							isLoading={loadingOrg}
							name="org"
							options={orgs}
							value={organization.org}
							onChange={handleSelect}
							error={orgErr.org}
						/>
					</div>
				</div>
				<div className="col-md-9 col-lg-6 row">
					<div className="col-sm-12">
						<h3 className="mb-3">Select Application</h3>
						<Select
							label="Application:"
							required
							name="app"
							isLoading={loadingApp}
							options={apps}
							value={organization.app}
							onChange={handleSelect}
							error={orgErr.app}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Organization
