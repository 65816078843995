import { useLocation } from "react-router-dom"
import { Tabs } from "../../../components/Tabs"
import AddBusOwner from "./AddBusOwner"
import AddSacco from "./AddSacco"
import { useEffect, useState } from "react"

const Add = () => {
	const location = useLocation()

	const [selected, setSelected] = useState(
		location && location.state && location.state.selected
	)
	const [selectedOwner, setSelectedOwner] = useState(
		location && location.state && location.state.selectedOwner
	)
	const [submittedSacco, setSubmittedSacco] = useState(false)

	const [active, setActive] = useState()

	useEffect(() => {
		console.log({ location })
		if (location && location.state && location.state.tab) {
			setActive(location.state.tab)
		}
		setTimeout(() => setActive(null), 500)
	}, [location && location.state])

	const renderSacco = () => {
		return (
			<AddSacco
				setSubmittedSacco={setSubmittedSacco}
				selected={selected}
				setSelected={setSelected}
			/>
		)
	}

	const renderBusOwner = () => {
		return (
			<AddBusOwner
				selected={selectedOwner}
				setSelected={setSelectedOwner}
				submittedSacco={submittedSacco}
			/>
		)
	}

	const options = [
		{
			title: "Sacco",
			data: renderSacco(),
		},
		{
			title: "Bus Owner",
			data: renderBusOwner(),
		},
	]

	return <Tabs options={options} activeTab={active} />
}

export default Add
