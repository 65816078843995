import { useState } from "react"
import { Input } from "../../../../../components/Input"

const Details = ({ reconciliation }) => {
  const handleFile = (e) => {}
  return (
    <div className="card">
      <div className="card-body d-flex justify-content-center">
        <form className="row col-md-8 justify-content-end">
          <div className="col-md-6">
            <Input
              label="Initiator"
              type="text"
              disabled
              value={reconciliation&&reconciliation.initiator}
              onChange={handleFile}
            />
          </div>
          <div className="col-md-6">
            <Input
              label="Transaction Date"
              type="text"
              disabled
              value={reconciliation&&reconciliation.transactionDate}
              onChange={handleFile}
            />
          </div>
          {/* <div className="col-md-4">
            <Input
              label="State"
              type="text"
              disabled
              value={reconciliation&&reconciliation.state}
              onChange={handleFile}
            />
          </div> */}
          <div className="col-md-6">
            <Input
              label="Status"
              type="text"
              disabled
              value={reconciliation&&reconciliation.status}
              onChange={handleFile}
            />
          </div>
          <div className="col-md-6">
            <Input
              label="RUN Number"
              type="text"
              disabled
              value={reconciliation&&reconciliation.runNumber}
              onChange={handleFile}
            />
          </div>
          {/* <div className="col-md-12">
            <Input
              label="Results"
              type="text"
              disabled
              value={reconciliation&&reconciliation.results}
              onChange={handleFile}
            />
          </div> */}
          <div className="col-md-6">
            <Input
              label="Total Payment Amount"
              type="text"
              disabled
              value={reconciliation&&reconciliation.totalPaymentAmount}
              onChange={handleFile}
            />
          </div>
          <div className="col-md-6">
            <Input
              label="Total Credit Amount"
              type="text"
              disabled
              value={reconciliation&&reconciliation.totalCreditAmount}
              onChange={handleFile}
            />
          </div>
          <div className="col-md-6">
            <Input
              label="Total M-Pesa Amount"
              type="text"
              disabled
              value={reconciliation&&reconciliation.totalMpesaAmount}
              onChange={handleFile}
            />
          </div>
          <div className="col-md-6">
            <Input
              label="Total Reversed M-Pesa Amount"
              type="text"
              disabled
              value={reconciliation&&reconciliation.totalReversedMpesaAmount}
              onChange={handleFile}
            />
          </div>
          <div className="col-md-6">
            <Input
              label="Total Token Amount"
              type="text"
              disabled
              value={reconciliation&&reconciliation.totalTokenAmount}
              onChange={handleFile}
            />
          </div>
          <div className="col-md-6">
            <Input
              label="Total Reversed Token Amount"
              type="text"
              disabled
              value={reconciliation&&reconciliation.totalReversedTokenAmount}
              onChange={handleFile}
            />
          </div>
          <div className="col-md-6">
            <Input
              label="Date Created"
              type="text"
              disabled
              value={reconciliation&&reconciliation.dateCreated}
              onChange={handleFile}
            />
          </div>
          <div className="col-md-6">
            <Input
              label="Date Modified"
              type="text"
              disabled
              value={reconciliation&&reconciliation.dateModified}
              onChange={handleFile}
            />
          </div>
        </form>
      </div>
    </div>
  )
}

export default Details
