import React, { useEffect, useState } from "react"
import { LoadingSpinner } from "../../../../components/LoadingSpinner"
import { Input } from "../../../../components/Input"
import useAxios from "../../../../hooks/useAxios"
import { RECONCILIATION_ROOT_API } from "../../../../constants/api"
import toastMessage from "../../../../utils/toastMessage"

const Account = ({ selected }) => {
	const [loading, setLoading] = useState(true)
	const [data, setData] = useState({})
	const [bank, setBank] = useState({})
	const axios = useAxios()

	useEffect(() => {
		if (selected && selected.publicId) {
			getData()
			getBankDetails()
		} else {
			setLoading(false)
		}
	}, [])

	async function getBankDetails() {
		const { err, res } = await axios({
			method: "GET",
			url: `${RECONCILIATION_ROOT_API}paymentServices/${selected.publicId}/bankDetails`,
			headers: null,
			params: {
				paymentServiceAccountPublicId: selected.publicId,
			},
		})

		if (err) {
			setLoading(false)
		}
		if (res) {
			setBank(res)
			setLoading(false)
		}
	}

	const getData = async () => {
		const { err, res, status } = await axios({
			method: "GET",
			url: `${RECONCILIATION_ROOT_API}paymentServices/${selected.publicId}`,
			headers: {},
			params: {
				paymentServiceAccountPublicId: selected.publicId,
			},
		})
		if (err) {
			if (err.messages) {
				toastMessage("error", err.messages[0])
			}
			setLoading(false)
			return
		}
		if (res) {
			setLoading(false)
			setData(res)
		}
	}

	return (
		<div style={{ minHeight: "80vh" }} className="card">
			<div className="card-body">
				<div className="d-flex justify-content-center">
					<div className="row align-items-start justify-content-around">
						{loading ? (
							<LoadingSpinner />
						) : (
							<>
								<div className="col-md-7 row">
									<h3 className="mb-3">Account Info</h3>
									<div className="col-md-6">
										<Input
											label="Name"
											disabled={true}
											value={data.name}
											// onChange={handleInput}
										/>
									</div>
									<div className="col-md-6">
										<Input
											label="Business Nature"
											disabled={true}
											value={data.businessNature}
											// onChange={handleInput}
										/>
									</div>
									<div className="col-md-6">
										<Input
											label="Account Type"
											disabled={true}
											value={data.accountType}
											// onChange={handleInput}
										/>
									</div>
									<div className="col-md-6">
										<Input
											label="Registration Number"
											disabled={true}
											value={data.registrationNumber}
											// onChange={handleInput}
										/>
									</div>
									<div className="col-md-6">
										<Input
											label="Registration Date"
											disabled={true}
											value={data.registrationDate}
											// onChange={handleInput}
										/>
									</div>
									<div className="col-md-6">
										<Input
											label="KRA PIN"
											disabled={true}
											value={data.kraPin}
											// onChange={handleInput}
										/>
									</div>
									<div className="col">
										<Input
											type="textarea"
											label="Notes"
											disabled={true}
											value={data.notes}
											// onChange={handleInput}
										/>
									</div>
								</div>
								<div className="col-md-4 row items">
									<h3 className="mb-3">Address Details</h3>
									<div className="col-md-6">
										<Input
											label="Country"
											disabled
											value={data.address && data.address.country}
										/>
									</div>
									<div className="col-md-6">
										<Input
											label="City"
											disabled
											value={data.address && data.address.city}
										/>
									</div>
									<div className="col-md-6">
										<Input
											label="Physical Location"
											disabled
											value={data.address && data.address.physicalLocation}
										/>
									</div>
									<div className="col-md-6">
										<Input
											label="Building Name"
											disabled
											value={data.address && data.address.building}
										/>
									</div>
									<div className="col-md-6">
										<Input
											label="Road Name"
											disabled
											value={data.address && data.address.roadName}
										/>
									</div>
									<div className="col-md-6">
										<Input
											label="Postal Address"
											disabled
											value={data.address && data.address.postalAddress}
										/>
									</div>
									<div className="col-md-6">
										<Input
											label="Postal Code"
											disabled
											value={data.address && data.address.country}
										/>
									</div>
									<div className="col-md-6">
										<Input
											label="Postal Town"
											disabled
											value={data.address && data.address.postalTown}
										/>
									</div>
								</div>

								<div className="col-md-7 row">
									<h3 className="mb-3">Contact Person Details</h3>
									<div className="col-md-6">
										<Input
											label="Full Name"
											disabled
											value={data.contactPerson && data.contactPerson.name}
										/>
									</div>
									<div className="col-md-6">
										<Input
											label="National ID"
											disabled
											value={
												data.contactPerson && data.contactPerson.nationalId
											}
										/>
									</div>
									<div className="col-md-6">
										<Input
											label="Phone Number"
											disabled
											value={
												data.contactPerson && data.contactPerson.phoneNumber
											}
										/>
									</div>
									<div className="col-md-6">
										<Input
											label="Email Address"
											disabled
											value={data.contactPerson && data.contactPerson.email}
										/>
									</div>
								</div>

								<div className="col-md-4 row align-items-start">
									<h3 className="mb-3">Bank Details</h3>
									<div className="col-md-6">
										<Input
											label="Bank Code"
											required
											disabled={true}
											value={bank.bankCode}
										/>
									</div>
									<div className="col-md-6">
										<Input
											label="Account Number"
											required
											disabled={true}
											value={bank.accountNumber}
										/>
									</div>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Account
