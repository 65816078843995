import { useEffect, useState } from "react"
import { Table } from "../../../components/Table"
import useAxios from "../../../hooks/useAxios"
import { RECONCILIATION_ROOT_API } from "../../../constants/api"
import toastMessage from "../../../utils/toastMessage"
import { useNavigate } from "react-router-dom/dist"

const ViewAll = () => {
	const [data, setData] = useState([])
	const [loading, setLoading] = useState(true)
	const [pagination, setPagination] = useState({})
	const axios = useAxios()
	const navigate = useNavigate()

	useEffect(() => {
		getData()
	}, [])

	const getData = async (params = {}) => {
		setLoading(true)
		const { err, res } = await axios({
			method: "GET",
			url: `${RECONCILIATION_ROOT_API}paymentServices`,
			params: {
				...params,
				accountType: "Sacco",
			},
			headers: null,
		})
		if (err) {
			if (err.messages) {
				toastMessage("error", err.messages[0])
			}
			console.log({ err })
			setLoading(false)
			return
		}
		if (res) {
			setLoading(false)
			const { content, number, numberOfElements, totalElements, totalPages } =
				res
			const pagination = {
				pageNumber: number + 1,
				pageCount: numberOfElements,
				totalCount: totalElements,
				pageCount: totalPages,
			}
			setPagination(pagination)
			setData(content)
		}
	}

	const handlePagination = async (page) => {
		if (!page) return
		getData({ page: page - 1 })
	}

	const handleRowPress = (row) => {
		navigate(`view/${row.publicId}/${row.name}`)
	}

	const handleEdit = (row) => {
		navigate("add", {
			state: { selected: row, tab: "sacco" },
		})
	}

	const handleViewBusOwners = (sacco) => {
		navigate("bus-owners", {
			state: { sacco },
		})
	}

	const headers = [
		{ title: "Service Number", key: "serviceNumber" },
		{ title: "Name", key: "name" },
		{ title: "Status", key: "status" },
		{ title: "KRA Pin", key: "kraPin" },
		{ title: "Account Type", key: "accountType" },
		{ title: "Business Nature", key: "businessNature" },
		{ title: "Registration Number", key: "registrationNumber" },
		{ title: "Registration Date", key: "registrationDate" },
		{ title: "Actions", key: "action" },
	]
	return (
		<>
			<div className="card">
				<div className="card-body">
					<Table
						headers={headers}
						data={data}
						// rowId={(value)=>value.publicId}
						isLoading={loading}
						handlePagination={handlePagination}
						totalPageCount={pagination.pageCount}
						page={pagination.pageNumber}
						showAdd
						rowPress={handleRowPress}
						handleAddPressed={() => navigate("add")}
						addButtonText="Add Account"
						actions={[
							{
								name: "View",
								onPress: (row) => handleRowPress(row),
							},
							{
								name: "Edit",
								onPress: (row) => handleEdit(row),
							},
							{
								name: "Bus Owners",
								onPress: (row) => handleViewBusOwners(row),
							},
						]}
					/>
				</div>
			</div>
		</>
	)
}

export default ViewAll
