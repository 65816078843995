import { useState } from "react"
import { Button } from "../../../../components/Button"
import { Input, Select } from "../../../../components/Input"
import useHandleFormValidate from "../../../../hooks/useHandleFormValidate"
import useAxios from "../../../../hooks/useAxios"
import { RECONCILIATION_ROOT_API } from "../../../../constants/api"
import toastMessage from "../../../../utils/toastMessage"

const Document = ({ handleClose, referenceId, getData }) => {
	const [document, setDocument] = useState({
		referenceType: "",
		documentName: "",
		file: "",
	})
	const [error, setError] = useState({})
	const [submitting, setSubmitting] = useState(false)
	const validate = useHandleFormValidate()
	const axios = useAxios()

	const handleFile = (e) => {
		if (error.file) {
			delete error.file
		}
		setDocument((prev) => ({
			...prev,
			file: e.target.files[0],
		}))
	}
	const handleInput = (e) => {
		const { name, value } = e.target
		if (error[name]) {
			delete error[name]
		}
		setDocument((prev) => ({
			...prev,
			[name]: value,
		}))
	}

	const handleSelect = (value, { name }) => {
		if (error[name]) {
			delete error[name]
		}
		setDocument((prev) => ({
			...prev,
			[name]: value,
		}))
	}

	const handleSubmit = async (e) => {
		e && e.preventDefault()
		const { error, valid } = await validate(document)
		setError(error)
		if (!valid) return
		setSubmitting(true)
		const form = new FormData()
		form.append("file", document.file)

		const { err, res, status } = await axios({
			method: "POST",
			url: `${RECONCILIATION_ROOT_API}files/upload`,
			headers: null,
			params: {
				referenceId,
				referenceType: document.referenceType && document.referenceType.value,
				documentName: document.documentName,
			},
			data: form,
		})

		if (err) {
			setSubmitting(false)
			if (err.messages) {
				err.messages.forEach((err) => {
					toastMessage("error", err)
				})
			} else {
				toastMessage("error", err)
			}
		}
		if (res || (status && status === 200)) {
			setSubmitting(false)
			toastMessage("success", `Document uploaded successfully.`)
			getData()
			handleClose()
		}

		console.log("Form: ", document)
	}

	const options = [
		{ label: "BoardResolution", value: "BoardResolution" },
		{
			label: "CertificateOfIncorporation",
			value: "CertificateOfIncorporation",
		},
		{ label: "NationalIdFront", value: "NationalIdFront" },
		{ label: "NationalIdBack", value: "NationalIdBack" },
		{ label: "BankStatement", value: "BankStatement" },
		{ label: "VehicleFront", value: "VehicleFront" },
		{ label: "VehicleSide", value: "VehicleSide" },
		{ label: "VehicleBack", value: "VehicleBack" },
		{ label: "LogBook", value: "LogBook" },
	]
	return (
		<div className="card">
			<div className="card-body">
				<Input
					name="documentName"
					value={document.documentName}
					onChange={handleInput}
					label="Document Name"
				/>
				<Select
					name="referenceType"
					required
					value={document.referenceType}
					onChange={handleSelect}
					error={error.referenceType}
					options={options}
					label="Reference Type"
				/>
				<Input
					onChange={handleFile}
					error={error.file}
					type="file"
					label="File"
					required
				/>
			</div>
			<div className="card-footer d-flex justify-content-end gap-3">
				<Button text="Cancel" onPress={handleClose} className="btn-secondary" />
				<Button
					isSubmitting={submitting}
					text="Submit"
					isL
					onPress={handleSubmit}
					className="btn-primary"
				/>
			</div>
		</div>
	)
}

export default Document
