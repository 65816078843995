import { Table } from "../../../../../components/Table"

const Activities = ({ reconciliation={} }) => {
    
  return (
    <div className="card">
      <div className="card-body">
        <Table
          headers={[
            { title: "Description", key: "description" },
            { title: "Date Created", key: "dateCreated" },
          ]}
          data={ []}
          // data={reconciliation.activities || []}
        />
      </div>
    </div>
  )
}

export default Activities
