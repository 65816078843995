import useApp from "../../hooks/useApp"
import "./style.css"

const FilterContainer = () => {

	const { filters } = useApp()
	return <div className="filter-body card">{filters}</div>
}

export default FilterContainer
