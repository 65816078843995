//DEFINE ROOT APIS
export const RECONCILIATION_ROOT_API = "https://uat.gateway.mobitill.com/cashless/v1/" //DEV
// export const RECONCILIATION_ROOT_API ="https://api.gateway.mobitill.com/cashless/v1/"
// export const MAIN_DASHBOARD_ROOT_API = "https://api.dev.mobitill.com";
export const MAIN_DASHBOARD_ROOT_API = "https://test.api.mobitill.com"
export const AUTH_API = "http://34.136.71.94:5000"
export const EPESI_API = "https://api.production.mobitill.com"
export const EPESI_DEV_API = "https://api.dev.mobitill.com"
export const OLD_EPESI_API = "https://test.api.mobitill.com"
export const OLD_EPESI_TOKEN =
	"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiJhNzI5YTI3ZS1hMzI5LTRiYzAtOTYwOC1mZGQxYzM3YWRlZjYiLCJpYXQiOjE1ODAyMTA1MzQsImV4cCI6MTg1MDIxMDUzNH0.OZtT-9gQAOi3RR1pBhR2U9B2RbUMGRG7uGN92J-JO8I"
export const EPESI_TOKEN =
	"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI4YTU4MzFkYy0xMGUzLTQ0Y2QtYTQ4My1jYjI2MjdhM2U4YWUiLCJpYXQiOjE2ODYyMTUwMzYsImV4cCI6MTc0OTI4NzAzNn0.iQpsBCblmN7nKebTEu2rfrX9pisQ-BKpy3wZKUeCdow"
export const EPESI_DEV_TOKEN =
	"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiJkNzQyN2UzNC0wZjAxLTQ0ZTgtOWE2Ny02YTk2ZmRiMDg0ZGQiLCJpYXQiOjE2ODYyMTUzMzQsImV4cCI6MTc0OTI4NzMzNH0.3QsfF_5jVWfMXqJAnp7aUBn4ACVJxU85F62-BmhosLs"
