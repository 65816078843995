import { useState } from "react"
import { Input } from "../../../../../components/Input"
import { Button } from "../../../../../components/Button"
import useAxios from "../../../../../hooks/useAxios"
import useHandleFormValidate from "../../../../../hooks/useHandleFormValidate"
import toastMessage from "../../../../../utils/toastMessage"
import { RECONCILIATION_ROOT_API } from "../../../../../constants/api"

const Documents = ({ getReconciliation, reconciliationPublicId }) => {
	const [add, setAdd] = useState({
		bankStatement: "",
		mpesaStatement: "",
	})
	const [error, setError] = useState({})
	const [loading, setLoading] = useState(false)
	const validateForm = useHandleFormValidate()
	const axios = useAxios()

	const handleFile = (e) => {
		const { name, files } = e.target
		if (error[name]) {
			delete error[name]
		}
		setAdd((prev) => ({
			...prev,
			[name]: files[0],
		}))
	}

	const handleSubmit = async (e) => {
		e.preventDefault()
		const { error, valid } = await validateForm(add)
		setError(error)
		if (!valid) return
		setLoading(true)

		const bankData = new FormData()
		bankData.append("file", add.bankStatement)

		const mpesaData = new FormData()
		mpesaData.append("file", add.mpesaStatement)

		const { err, res } = await axios({
			method: "POST",
			url: `${RECONCILIATION_ROOT_API}reconciliations/${reconciliationPublicId}/document`,
			headers: null,
			data: bankData,
			params: {
				reconciliationPublicId,
				documentType: "BankStatements",
			},
		})
		if (err) {
			setLoading(false)
			if (err.messages) {
				toastMessage("error", err.messages[0])
			}
			return
		}
		if (res) {
			const { err, res } = await axios({
				method: "POST",
				url: `${RECONCILIATION_ROOT_API}reconciliations/${reconciliationPublicId}/document`,
				headers: null,
				data: mpesaData,
				params: {
					reconciliationPublicId,
					documentType: "MpesaTransactions",
				},
			})
			if (err) {
				setLoading(false)
				if (err.messages) {
					toastMessage("error", err.messages[0])
				}
				return
			}
			if (res) {
				setLoading(false)
				toastMessage(
					"success",
					"Reconciliation documents was uploaded sucessfully."
				)
				getReconciliation()
			}
		}
	}

	return (
		<div className="card">
			<div className="card-body d-flex justify-content-center ">
				<form className="row col-md-8 justify-content-end">
					<div className="col-md-6">
						<Input
							label="Bank Statement"
							type="file"
							error={error.bankStatement}
							name="bankStatement"
							onChange={handleFile}
						/>
					</div>
					<div className="col-md-6">
						<Input
							label="M-Pesa Statement"
							type="file"
							error={error.mpesaStatement}
							name="mpesaStatement"
							onChange={handleFile}
						/>
					</div>

					<div className="col-md-2 d-flex justify-content-end">
						<Button
							className="btn-primary"
							text="Submit"
							isSubmitting={loading}
							onPress={handleSubmit}
						/>
					</div>
				</form>
			</div>
		</div>
	)
}

export default Documents
