import "./App.css"
import "react-toastify/dist/ReactToastify.css"
import { ToastContainer } from "react-toastify"
import { Route, Routes } from "react-router-dom"

//screens
import SplashScreen from "./app/screens/Common/Splash"
import LoginScreen from "./app/screens/Common/Login"
import ForgotPasswordScreen from "./app/screens/Common/ForgotPassword"
import Dashboard from "./app/screens/Dashboard"

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<SplashScreen />} />
        <Route path="login" element={<LoginScreen />} />
        <Route path="forgot-password" element={<ForgotPasswordScreen />} />
        <Route path="dashboard/*" element={<Dashboard />} />
      </Routes>
      <ToastContainer
        autoClose={5000}
        hideProgressBar={true}
        closeOnClick={true}
        pauseOnHover={true}
        draggable={true}
        progress={undefined}
      />
    </div>
  )
}

export default App
