import { AsyncSelect, Input, Select } from "../../../../../components/Input"
import { Button } from "../../../../../components/Button"
import useApp from "../../../../../hooks/useApp"
import { useEffect, useState } from "react"
import { RECONCILIATION_ROOT_API } from "../../../../../constants/api"
import useAxios from "../../../../../hooks/useAxios"

const StatementFilters = ({ getData, setFilterItems }) => {
	const { setShowFilter } = useApp()
	const [items, setItems] = useState({
		accountNo: "",
		paymentMode: "",
		paymentResult: "",
		transactionId: "",
		paymentServiceAccountPublicId: "",
		amount: "",
		confirmedAmount: "",
	})
	const [loading, setLoading] = useState(true)
	const [accounts, setAccounts] = useState([])

	const axios = useAxios()

	useEffect(() => {
		getServiceAccounts()
	}, [])

	const getServiceAccounts = async (params = {}) => {
		setLoading(true)
		const { err, res } = await axios({
			method: "GET",
			url: `${RECONCILIATION_ROOT_API}paymentServices`,
			params: {
				...params,
			},
			headers: null,
		})
		if (err) {
			setLoading(false)
			return
		}
		if (res) {
			setLoading(false)
			const { content } = res
			let data = []
			if (content && Array.isArray(content)) {
				content.forEach((item) => {
					let entry = { ...item }
					entry.label = item.name
					entry.value = item.publicId
					data.push(entry)
				})
			}
			setAccounts(data)
		}
	}

	const handleInput = (e) => {
		const { name, value } = e.target
		setItems((prev) => ({
			...prev,
			[name]: value,
		}))
	}

	const handleSelect = (value, { name }) => {
		setItems((prev) => ({
			...prev,
			[name]: value,
		}))
	}

	const applyFilters = async () => {
		await setFilterItems(items)
		let filters = { ...items }
		if (items.paymentMode) {
			filters.paymentMode = items.paymentMode.value
		}
		if (items.paymentResult) {
			filters.paymentResult = items.paymentResult.value
		}
		if (items.paymentServiceAccountPublicId) {
			filters.paymentServiceAccountPublicId =
				items.paymentServiceAccountPublicId.value
		}

		getData({ ...filters })
	}

	const loadOptions = async (inputValue, callback) => {
		//get account searched()
		// setTimeout(() => {
		//   callback());
		// }, 1000);
	}

	return (
		<>
			<div className="filter-top">
				<div className="filter-top-items">
					<h1>Filters</h1>
					<Button
						onPress={() => setShowFilter(false)}
						className=""
						icon="bx bx-x"
					/>
				</div>
			</div>
			<div className="filter-center">
				<Input
					label="Account Number"
					name="accountNo"
					value={items.accountNo}
					onChange={handleInput}
				/>
				<AsyncSelect
					label="Service Account"
					isLoading={loading}
					name="paymentServiceAccountPublicId"
					value={items.paymentServiceAccountPublicId}
					onChange={handleSelect}
					loadOptions={loadOptions}
					defaultOptions={accounts}
				/>
				<Input
					label="Amount"
					name="amount"
					type="number"
					value={items.amount}
					onChange={handleInput}
				/>
				<Input
					label="Confirmed Amount"
					name="confirmedAmount"
					type="number"
					value={items.confirmedAmount}
					onChange={handleInput}
				/>
				<Input
					label="Transaction ID"
					name="transactionId"
					value={items.transactionId}
					onChange={handleInput}
				/>
				<Select
					label="Payment Mode"
					name="paymentMode"
					value={items.paymentMode}
					onChange={handleSelect}
					options={[
						{ label: "Mpesa", value: "Mpesa" },
						{ label: "Card", value: "Card" },
						{ label: "Token", value: "Token" },
					]}
				/>
				<Select
					label="Payment Result"
					name="paymentResult"
					value={items.paymentResult}
					onChange={handleSelect}
					options={[
						{ label: "SUCCESS", value: "SUCCESS" },
						{ label: "FAILED", value: "FAILED" },
						{ label: "PENDING", value: "PENDING" },
						{ label: "INVALID_OPERATION", value: "INVALID_OPERATION" },
						{
							label: "REQUIRES_AUTHORIZATION",
							value: "REQUIRES_AUTHORIZATION",
						},
					]}
				/>
			</div>

			<div className="filter-bottom">
				<div className="filter-bottom-items ">
					<Button
						onPress={() => (
							getData({
								accountNo: "",
								paymentMode: "",
								paymentResult: "",
								transactionId: "",
								paymentServiceAccountPublicId: "",
								amount: "",
								confirmedAmount: "",
							}),
							setShowFilter(false)
						)}
						className="btn-secondary"
						text="Reset"
					/>
					<Button
						onPress={() => (applyFilters(), setShowFilter(false))}
						className="btn-primary"
						text="Apply"
					/>
				</div>
			</div>
		</>
	)
}

export default StatementFilters
