import { useEffect, useState } from "react"
import { RECONCILIATION_ROOT_API } from "../../../../constants/api"
import { Table } from "../../../../components/Table"
import useAxios from "../../../../hooks/useAxios"
import moment from "moment"
import { Modal } from "../../../../components/Modal"
import { Filters } from "./Reconciliation/Filters"
import { useNavigate } from "react-router-dom/dist"
import Add from "./Reconciliation/Add"

const Reconciliations = () => {
  const [data, setData] = useState([])
  const [pagination, setPagination] = useState({})
  const [loading, setLoading] = useState(true)
  const [dates, setDates] = useState({
    startDate: moment
      .utc(new Date())
      .local()
      .subtract("day", 1)
      .format("YYYY-MM-DD"),
    endDate: new Date().toISOString().slice(0, 10),
    errors: {},
  })
  const [showFilter, setShowFilter] = useState(false)
  const [showAdd, setShowAdd] = useState(false)

  const axios = useAxios()
  const navigate = useNavigate()

  useEffect(() => {
    getData()
  }, [])

  const getData = async (params = {}) => {
    setLoading(true)
    const { err, res } = await axios({
      method: "GET",
      url: `${RECONCILIATION_ROOT_API}reconciliations`,
      params: {
        startDate: dates.startDate,
        endDate: dates.endDate,
        ...params
      },
      headers: null,
    })
    if (err) {
      setLoading(false)
      return
    }
    if (res) {
      setLoading(false)
      const { content, number, numberOfElements, totalElements, totalPages } =
        res
      setData(content)
      const pagination = {
				pageNumber: number + 1,
				pageCount: numberOfElements,
				totalCount: totalElements,
				pageCount: totalPages,
			}
			setPagination(pagination)
    }
  }

  const handleView = (item) => {
    navigate("/dashboard/reconciliation/cashless/view", {
      state: { reconciliationPublicId: item.publicId, reconciliation: item },
    })
  }

  const handleInput = (e) => {
    const { name, value } = e.target
    if (dates.errors[name]) {
      delete dates.errors[name]
    }
    setDates((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleReset = () => {
    setDates({
      startDate: moment
        .utc(new Date())
        .local()
        .subtract("day", 1)
        .format("YYYY-MM-DD"),
      endDate: new Date().toISOString().slice(0, 10),
      errors: {},
    })
  }

  const handlePagination = async (page) => {
		if (!page) return
		getData({ page: page - 1 })
	}
  const headers = [
    { title: "RUN", key: "runNumber" },
    { title: "Initiator", key: "initiator" },
    { title: "Date", key: "transactionDate" },
    { title: "State", key: "state" },
    { title: "Status", key: "status" },
    // { title: "Results", key: "results" },
    { title: "Total Amount", key: "totalPaymentAmount" },
    // { title: "Total Credit", key: "totalCreditAmount" },
    // { title: "Total M-Pesa", key: "totalMpesaAmount" },
    // { title: "Total Reversed M-Pesa", key: "totalReversedMpesaAmount" },
    // { title: "Total Token", key: "totalTokenAmount" },
    // { title: "Total Reversed Token", key: "totalReversedTokenAmount" },
    { title: "Bank Statement Present", key: "bankStatementPresent" },
    { title: "M-Pesa Transactions Present", key: "mpesaTransactionsPresent" },
    { title: "Date Created", key: "dateCreated" },
    // { title: "Actions", key: "action" },
  ]

  return (
    <>
      <div class="card text-start">
        <div class="card-body">
          <Table
            isLoading={loading}
            data={data}
            showAdd
            addButtonText="Reconcile"
            handlePagination={handlePagination}
						totalPageCount={pagination.pageCount}
						page={pagination.pageNumber}
            handleAddPressed={() => setShowAdd(true)}
            headers={headers}
            rowPress={handleView}
            filters={[
              {
                title: "Date",
                selected:
                  dates.startDate !== "" && dates.endDate
                    ? moment(dates.startDate).format("lll") +
                      " - " +
                      moment(dates.endDate).format("lll")
                    : "",
                onPress: () => setShowFilter(true),
                icon: "bx bx-calendar",
                isList: true,
                default_key: "label",
              },
            ]}
            actions={[
              {
                name: "Amount",
                onPress: null,
              },
            ]}
          />
        </div>
      </div>
      <Modal
        title="Filter Dates"
        show={showFilter}
        handleClose={() => setShowFilter(false)}
      >
        <Filters
          handleInput={handleInput}
          handleApply={() => (
            setLoading(true), getData(), setShowFilter(false)
          )}
          handleReset={handleReset}
          isSubmitting={loading}
          filterBy={dates}
        />
      </Modal>
      <Modal
        title="Add a reconciliation"
        show={showAdd}
        handleClose={() => setShowAdd(false)}
      >
        <Add getData={getData} handleClose={() => setShowAdd(false)} />
      </Modal>
    </>
  )
}

export default Reconciliations
