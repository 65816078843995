import { useState } from "react"
import { Input, Select } from "../../../../../components/Input"
import useAxios from "../../../../../hooks/useAxios"
import { Button } from "../../../../../components/Button"
import { RECONCILIATION_ROOT_API } from "../../../../../constants/api"
import toastMessage from "../../../../../utils/toastMessage"

const Approve = ({ selected, handleClose, reconciliationPublicId }) => {
  const [loading, setLoading] = useState(false)
  const axios = useAxios()

  const handleSubmit = async () => {
    let batch = []

    await selected.settlements.forEach((entry) => {
      batch.push(entry.publicId)
    })
    if (batch.length === 0) return
    setLoading(true)
    const { err, res } = await axios({
      method: "POST",
      url: `${RECONCILIATION_ROOT_API}reconciliations/${reconciliationPublicId}/settlementBatch`,
      params: {
        reconciliationPublicId,
      },
      data: {
        settlementPublicIds: batch,
      },
      headers: null,
    })
    if (err) {
      setLoading(false)
      if (err.messages) {
        err.messages.forEach((err) => {
          toastMessage("error", err)
        })
      }
      return
    }
    if (res) {
      setLoading(false)
      toastMessage("success", "Batch Approval successfully.")
    }
  }

  return (
    <div className="card">
      <div className="card-body">
        <Input label="Batch Number" disabled value={selected.batchNumber} />
        <Input label="Status" disabled value={selected.status} />
        <Input
          label="Total Payment Amount"
          disabled
          value={selected.totalPaymentAmount}
        />
        <Input
          label="Total Settlement Amount"
          disabled
          value={selected.totalSettlementAmount}
        />
        <Input
          label="Total Commission Amount"
          disabled
          value={selected.totalCommissionAmount}
        />
      </div>
      <div className="card-footer d-flex justify-content-end gap-3">
        <Button
          onPress={() => handleClose()}
          className="btn-secondary"
          text="Cancel"
        />
        <Button
          isSubmitting={loading}
          onPress={handleSubmit}
          className="btn-primary"
          text="Approve"
        />
      </div>
    </div>
  )
}

export default Approve
