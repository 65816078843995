import { useState } from "react"
import { useLocation } from "react-router-dom"
import { Tabs } from "../../../../../components/Tabs"
import TransactionStatements from "../Transactions/TransactionStatements"
import TransactionStatementsSummary from "../Transactions/TransactionStatementsSummary"
import BanksStatements from "../BanksStatements/BanksStatements"
import MpesaStatements from "../Mpesa/MpesaStatements"
import MpesaStatementsSummary from "../Mpesa/MpesaStatementsSummary"
import Settlements from "../Settlements/Settlements"
import ExceptionReports from "../Exception/ExceptionReports"
import Documents from "./Documents"
import Details from "./Details"
import Activities from "./Activities"
import BatchSettlements from "../Settlements/BatchSettlements"
import FundTransfers from "../Settlements/FundTransfers"
import Disputes from "../Disputes/Disputes"
import { RECONCILIATION_ROOT_API } from "../../../../../constants/api"
import useAxios from "../../../../../hooks/useAxios"

const Reconciliation = () => {
	const location = useLocation() //reconciliationPublicId
	const axios = useAxios()
	const [active, setActive] = useState(null)

	const [transActive, setTransActive] = useState(null)
	const [viewTransDisputes, setViewTransDisputes] = useState()

	const [banksActive, setBanksActive] = useState(null)
	const [bankStDisputes, setViewBankStDisputes] = useState()

	const [exeActive, setExeActive] = useState(null)
	const [exeReportDispute, setViewExeReportDispute] = useState()

	const [statementActive, setStatementActive] = useState(null)
	const [stReportDispute, setViewStReportDispute] = useState()

	const [reconciliationPublicId] = useState(
		location.state && location.state.reconciliationPublicId
	)
	const [reconciliation, setReconciliation] = useState(
		location.state && location.state.reconciliation
	)

	const getReconciliation = async (params = {}) => {
    if(!reconciliationPublicId) return
		const { err, res } = await axios({
			method: "GET",
			url: `${RECONCILIATION_ROOT_API}reconciliations/${reconciliationPublicId}`,
			params: {
				reconciliationPublicId,
			},
			headers: null,
		})
		if (err) return
		if (res) {
			setReconciliation(res)
		}
	}

	function renderDocuments() {
		return <Documents getReconciliation={getReconciliation} reconciliationPublicId={reconciliationPublicId} />
	}
	function renderDetails() {
		return <Details reconciliation={reconciliation} />
	}
	function renderActivities() {
		return <Activities reconciliation={reconciliation} />
	}
	function renderTransactionStatements() {
		return (
			<TransactionStatements
				setTransActive={setTransActive}
				setViewTransDisputes={setViewTransDisputes}
				reconciliationPublicId={reconciliationPublicId}
			/>
		)
	}
	function renderTransactionDisputes() {
		return <Disputes transActive={transActive} entity={viewTransDisputes} />
	}
	function renderBankDisputes() {
		return <Disputes transActive={banksActive} entity={bankStDisputes} />
	}
	function renderExceptionDisputes() {
		return <Disputes transActive={exeActive} entity={exeReportDispute} />
	}
	function renderSettlementDisputes() {
		return <Disputes transActive={statementActive} entity={stReportDispute} />
	}
	function renderTransactionStatementsSummary() {
		return (
			<TransactionStatementsSummary
				setTransActive={setTransActive}
				setViewTransDisputes={setViewTransDisputes}
				reconciliationPublicId={reconciliationPublicId}
			/>
		)
	}

	function renderBankStatements() {
		return (
			<BanksStatements
				setBanksActive={setBanksActive}
				setViewBankStDisputes={setViewBankStDisputes}
				reconciliationPublicId={reconciliationPublicId}
			/>
		)
	}

	function renderMpesaStatements() {
		return <MpesaStatements reconciliationPublicId={reconciliationPublicId} />
	}
	function renderMpesaStatementsSummary() {
		return (
			<MpesaStatementsSummary reconciliationPublicId={reconciliationPublicId} />
		)
	}
	function renderSettlements() {
		return (
			<Settlements
				setStatementActive={setStatementActive}
				setViewStReportDispute={setViewStReportDispute}
				reconciliationPublicId={reconciliationPublicId}
			/>
		)
	}
	function renderBatchSettlements() {
		return <BatchSettlements reconciliationPublicId={reconciliationPublicId} />
	}
	function renderFundTransfers() {
		return <FundTransfers reconciliationPublicId={reconciliationPublicId} />
	}
	function renderExceptionReports() {
		return (
			<ExceptionReports
				setViewExeReportDispute={setViewExeReportDispute}
				setExeActive={setExeActive}
				reconciliationPublicId={reconciliationPublicId}
			/>
		)
	}

	function renderTransactions() {
		let options = [
			{
				title: "Summary",
				data: renderTransactionStatementsSummary(),
			},
			{
				title: "Detailed",
				data: renderTransactionStatements(),
			},
		]
		if (viewTransDisputes) {
			options.push({
				title: "Disputes",
				data: renderTransactionDisputes(),
			})
		}
		return (
			<div className="card">
				<Tabs options={options} activeTab={transActive} />
			</div>
		)
	}
	function renderMpesa() {
		return (
			<div className="card">
				<Tabs
					options={[
						{
							title: "Summary",
							data: renderMpesaStatementsSummary(),
						},
						{
							title: "Detailed",
							data: renderMpesaStatements(),
						},
					]}
				/>
			</div>
		)
	}

	function renderReconciliation() {
		return (
			<div className="card">
				<Tabs
					options={[
						{
							title: "Details",
							data: renderDetails(),
						},
						{
							title: "Activities",
							data: renderActivities(),
						},
						{
							title: "Add Documents",
							data: renderDocuments(),
						},
					]}
				/>
			</div>
		)
	}

	function renderSettlementsStatements() {
		let options = [
			{
				title: "Single",
				data: renderSettlements(),
			},
			{
				title: "Batch",
				data: renderBatchSettlements(),
			},
			{
				title: "Fund Transfers",
				data: renderFundTransfers(),
			},
		]

		if (stReportDispute) {
			options.push({
				title: "Disputes",
				data: renderSettlementDisputes(),
			})
		}
		return (
			<div className="card">
				<Tabs options={options} activeTab={statementActive} />
			</div>
		)
	}

	function renderBanksStatements() {
		let options = [
			{
				title: "Statements",
				data: renderBankStatements(),
			},
		]

		if (bankStDisputes) {
			options.push({
				title: "Disputes",
				data: renderBankDisputes(),
			})
		}

		return (
			<div className="card">
				<Tabs options={options} activeTab={banksActive} />
			</div>
		)
	}

	function renderExceptionsReports() {
		let options = [
			{
				title: "Reports",
				data: renderExceptionReports(),
			},
		]

		if (exeReportDispute) {
			options.push({
				title: "Disputes",
				data: renderExceptionDisputes(),
			})
		}
		return (
			<div className="card">
				<Tabs options={options} activeTab={exeActive} />
			</div>
		)
	}

	const options = [
		{
			title: "Reconciliation",
			data: renderReconciliation(),
		},
		{
			title: "Transactions",
			data: renderTransactions(),
		},
		{
			title: "Banks Statements",
			data: renderBanksStatements(),
		},
		{
			title: "M-Pesa Statements",
			data: renderMpesa(),
		},
		{
			title: "Exception Reports",
			data: renderExceptionsReports(),
		},
		{
			title: "Settlement Statements",
			data: renderSettlementsStatements(),
		},
	]
	return <Tabs options={options} active={active} />
}

export default Reconciliation
