import { Outlet, Route, Routes } from "react-router-dom"

//IMPORT ALL RECONCILIATION SCREENS
import CashlessReconciliations from "./Cashless"
import ViewCashlessReconciliation from "./Cashless/Reconciliation"
import AddCashlessReconciliation from "./Cashless/Reconciliation/Add"

import ViewDocuments from "../BankAccounts/Documents/ViewAll"
import AddDocuments from "../BankAccounts/Documents/Add"

//Render all reconciliations routes for cashless, mapato, pesalink and reports

const Index = () => {
	return (
		<>
			<Routes>
				<Route path="" element={<CashlessReconciliations />} />{" "}
				{/**Change to dashboard or delete */}
				<Route path="cashless">
					<Route path="" element={<CashlessReconciliations />} />
					<Route path="add" element={<AddCashlessReconciliation />} />
					<Route path="view" element={<ViewCashlessReconciliation />} />
				</Route>
				<Route path="documents">
					<Route path="" element={<ViewDocuments />} />
					<Route path="add" element={<AddDocuments />} />
        </Route>
			</Routes>

			<Outlet />
		</>
	)
}

export default Index
