import { useEffect, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom/dist"
import { StepperItem, Steppers } from "../../../components/Steppers"
import AccountDetails from "./Forms/AccountDetails"
import Address from "./Forms/Address"
import ContactPerson from "./Forms/ContactPerson"
import BankDetails from "./Forms/BankDetails"
import Documents from "./Forms/Documents"
import Organization from "./Forms/Organization"
import useHandleFormValidate from "../../../hooks/useHandleFormValidate"
import useAxios from "../../../hooks/useAxios"
import { RECONCILIATION_ROOT_API } from "../../../constants/api"
import toastMessage from "../../../utils/toastMessage"

const AddSacco = ({ setSubmittedSacco, selected, setSelected }) => {
	const navigate = useNavigate()
	// const location = useLocation()
	const validate = useHandleFormValidate()
	const axios = useAxios()

	// const [selected, setSelected] = useState(
	// 	location && location.state && location.state.selected
	// )
	const [step, setStep] = useState(0)
	const [organization, setOrganization] = useState({
		org: "",
		app: "",
	})
	const [orgErr, setOrgErr] = useState({})

	const [details, setDetails] = useState({
		name: organization.org.label,
		businessNature: "",
		accountType: { label: "Sacco", value: "Sacco" },
		registrationNumber: "",
		registrationDate: "",
		kraPin: "",
		notes: "",
	})
	const [detailsErr, setDetailsErr] = useState({})
	const [address, setAddress] = useState({
		country: "",
		city: "",
		physicalLocation: "",
		building: "",
		roadName: "",
		postalAddress: "",
		postalCode: "",
		postalTown: "",
	})
	const [addressErr, setAddressErr] = useState({})
	const [contactPerson, setContactPerson] = useState({
		name: "",
		email: "",
		phoneNumber: "",
		nationalId: "",
		// credentialEmail: "",
		// credentialPhoneNumber: "",
	})
	const [contactPersonErr, setContactPersonErr] = useState({})
	const [bank, setBank] = useState({ bankCode: "", accountNumber: "" })
	const [bankErr, setBankErr] = useState({})

	const [submitting, setSubmitting] = useState(false)
	const [submit, setSubmit] = useState(false)

	useEffect(() => {
		if (selected) {
			if(step===0){
				setStep(1)
			}
			let dt = {
				name: selected.name,
				businessNature: selected.businessNature,
				accountType: {
					label: selected.accountType,
					value: selected.accountType,
				},
				registrationNumber: selected.registrationNumber,
				registrationDate: selected.registrationDate,
				kraPin: selected.kraPin,
				notes: selected.notes,
			}
			let cp = {
				name: selected.contactPerson && selected.contactPerson.name,
				email: selected.contactPerson && selected.contactPerson.email,
				phoneNumber:
					selected.contactPerson && selected.contactPerson.phoneNumber,
				nationalId: selected.contactPerson && selected.contactPerson.nationalId,
				// credentialEmail: selected.credentials && selected.credentials.email,
				// credentialPhoneNumber: selected.credentials && selected.credentials.phoneNumber,
			}
			let org = {
				org: { label: selected.organizationId, value: selected.organizationId },
				app: { label: selected.applicationId, value: selected.applicationId },
			}
			setOrganization(org)
			setDetails(dt)
			setAddress(selected.address)
			setContactPerson(cp)
		}
	}, [selected])

	const handleNext = async (current) => {
		setSubmit(false)
		if (current == 0) {
			if(selected){
				setStep(current+1)
				return
			}
			const { error, valid } = await validate(organization)
			if (error) setOrgErr(error)
			if (valid) setStep(current + 1)
			return
		}
		if (current === 1) {
			let toValidate = { ...details }
			delete toValidate.notes
			const { error, valid } = await validate(toValidate)
			if (error) setDetailsErr(error)
			if (valid) setStep(current + 1)
			return
		}
		if (current === 2) {
			const { error, valid } = await validate(address)
			if (error) setAddressErr(error)
			if (valid) setStep(current + 1)
			setSubmit(true)
			return
		}
		if (current === 4) {
			setSubmit(true)
		}
		setStep(current + 1)
	}

	const handleSubmit = async (e) => {
		e && e.preventDefault()
		if (step === 3) {
			submitServiceAcc()
		}
		if (step === 5) {
			setSubmit(true)
			submitBankDetails()
		}
	}

	const submitServiceAcc = async () => {
		let toValidate = { ...contactPerson }
		delete toValidate.email
		delete toValidate.phoneNumber
		if (selected && selected.publicId) {
			delete toValidate.credentialEmail
			delete toValidate.credentialPhoneNumber
		}
		const { error, valid } = await validate(toValidate)
		if (error) setContactPersonErr(error)
		if (!valid) return

		setSubmitting(true)
		let data = {
			accountType: details.accountType && details.accountType.value,
			name: details.name,
			businessNature: details.businessNature,
			registrationNumber: details.registrationNumber,
			registrationDate: details.registrationDate,
			kraPin: details.kraPin,
			contactPerson: {
				name: contactPerson.name,
				email: contactPerson.email,
				phoneNumber: contactPerson.phoneNumber,
				nationalId: contactPerson.nationalId,
			},
			address: {
				country: address.country,
				city: address.city,
				physicalLocation: address.physicalLocation,
				building: address.building,
				roadName: address.roadName,
				postalAddress: address.postalAddress,
				postalCode: address.postalCode,
				postalTown: address.postalTown,
			},
			notes: details.notes,
		}
		let publicId = {}
		if (selected && selected.publicId) {
			publicId = {
				publicId: selected.publicId,
			}
		}
		if(!selected||!selected.publicId){
			data.organizationId = organization.org && organization.org.value
			data.applicationId = organization.app && organization.app.value
		}
		const { err, res, status } = await axios({
			method: selected && selected.publicId ? "PUT" : "POST",
			url: `${RECONCILIATION_ROOT_API}paymentServices`,
			headers: null,
			data: {
				...publicId,
				...data,
			},
		})
		if (err) {
			setSubmitting(false)
			if (err.messages && err.messages[0]) {
				toastMessage("error", err.messages[0])
			}
			return
		}
		if (res || (status && status === 200)) {
			setSelected(res)
			toastMessage(
				"success",
				`Service Account ${selected ? "updated" : "added"} successfully.`
			)
			setSubmittedSacco(true)
			setSubmit(false)
			setSubmitting(false)
		}
	}

	const submitBankDetails = async () => {
		let payload = {
			bankCode: bank.bankCode,
			accountNumber: bank.accountNumber,
		}
		if (bank.publicId) {
			payload.publicId = bank.publicId
		}
		const { error, valid } = await validate(payload)
		if (error) setBankErr(error)
		if (!valid) return

		setSubmitting(true)
		const { err, res, status } = await axios({
			method: bank && bank.publicId ? "PUT" : "POST",
			url: `${RECONCILIATION_ROOT_API}paymentServices/${selected.publicId}/bankDetails`,
			headers: null,
			data: payload,
		})

		if (err) {
			if (err.messages) {
				err.messages.forEach((err) => {
					toastMessage("error", err)
				})
			} else {
				toastMessage("error", err)
			}
		}
		if (res || (status && status === 200)) {
			toastMessage(
				"success",
				`Bank details ${bank.publicId ? "updated" : "added"} successfully.`
			)
			setBank(res)
			setSubmit(false)
			setSubmitting(false)
		}
	}
	
	return (
		<div className="card">
			<div className="card-body">
				<Steppers
					currentStep={step}
					close={() => navigate("/dashboard/service_accounts")}
				>
					<span>Organization</span>
					<span>Account details</span>
					<span>Address details</span>
					<span>Contact Person</span>
					<span>Documents</span>
					<span>Bank details</span>
				</Steppers>
				<StepperItem
					handleNext={handleNext}
					stepsCount={6}
					setSubmit={submit}
					handleSetSubmit={setSubmit}
					submitting={submitting}
					handleSubmit={handleSubmit}
					setStep={setStep}
					step={step}
				>
					{step === 0 ? (
						<Organization
							organization={organization}
							setOrganization={setOrganization}
							setDetails={setDetails}
							orgErr={orgErr}
						/>
					) : step === 1 ? (
						<AccountDetails
							details={details}
							setDetails={setDetails}
							detailsErr={detailsErr}
						/>
					) : step === 2 ? (
						<Address
							address={address}
							setAddress={setAddress}
							addressErr={addressErr}
						/>
					) : step === 3 ? (
						<ContactPerson
							contactPerson={contactPerson}
							setContactPerson={setContactPerson}
							contactPersonErr={contactPersonErr}
						/>
					) : step === 4 ? (
						<Documents selected={selected} />
					) : (
						<BankDetails
							selected={selected}
							bank={bank}
							setBank={setBank}
							bankErr={bankErr}
						/>
					)}
				</StepperItem>
			</div>
		</div>
	)
}

export default AddSacco
