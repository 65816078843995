import { Outlet, Route, Routes } from "react-router-dom"

import ViewAll from "./ViewAll"
import ViewSingle from "./ViewSingle"
import Add from "./Add"
import ViewBusOwners from "./ViewBusOwners"

const Index = () => {
	return (
		<>
			<Routes>
				<Route path="/" element={<ViewAll />} />
				<Route path="add" element={<Add />} />
				<Route path="view/:id/:name" element={<ViewSingle />} />
				<Route path="bus-owners" element={<ViewBusOwners />} />
			</Routes>

			<Outlet />
		</>
	)
}

export default Index
