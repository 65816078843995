import { useEffect, useState } from "react"
import { Table } from "../../../../../components/Table"
import useAxios from "../../../../../hooks/useAxios"
import { RECONCILIATION_ROOT_API } from "../../../../../constants/api"
import { Modal } from "../../../../../components/Modal"
import BankStatemnt from "./BankStatemnt"
import Filters from "./Filters"
import useApp from "../../../../../hooks/useApp"


const BanksStatements = ({
  reconciliationPublicId,
  setViewBankStDisputes,
  setBanksActive,
}) => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [show, setShow] = useState(false)
  const [selected, setSelected] = useState({})
  const [pagination, setPagination] = useState({})
  const [filterItems, setFilterItems] = useState({
		accountNumber: "",
		transactionType: "",
		transactionReference: "",
		creditAmount: "",
	})
  const axios = useAxios()
  const { setShowFilter, setFilters } = useApp()

  useEffect(() => {
    if (reconciliationPublicId) {
      getData()
    } else {
      setLoading(false)
    }
  }, [])

  const getData = async (params = {}) => {
    setLoading(true)
    const { err, res } = await axios({
      method: "GET",
      url: `${RECONCILIATION_ROOT_API}reconciliations/${reconciliationPublicId}/bankStatements`,
      params: {
        reconciliationPublicId,
        ...filterItems,
        ...params
      },
      headers: null,
    })
    if (err) {
      setLoading(false)
      return
    }
    if (res) {
      setLoading(false)
      const { content, number, numberOfElements, totalElements, totalPages } =
        res
      setData(content)
      const pagination = {
				pageNumber: number + 1,
				pageCount: numberOfElements,
				totalCount: totalElements,
				pageCount: totalPages,
			}
			setPagination(pagination)
    }
  }

  const handleDispute = async (item) => {
    await setViewBankStDisputes(item)
    await setBanksActive("disputes")
    setBanksActive()
  }

  const handlePagination = async (page) => {
		if (!page) return
		getData({ page: page - 1 })
	}

  const handleShowFilters=async()=>{
		await setFilters(
			<Filters getData={getData} setFilterItems={setFilterItems} />
		)
		setShowFilter(true)
	}

  const headers = [
    { title: "Account Name", key: "accountName" },
    { title: "Account Number", key: "accountNumber" },
    { title: "Credit Amount", key: "creditAmount" },
    { title: "Reference", key: "transactionReference" },
    { title: "Transaction Type", key: "transactionType" },
    { title: "Transaction Date", key: "transactionDate" },
    { title: "Narration", key: "narration" },
    { title: "Created At", key: "dateCreated" },
    { title: "Modified At", key: "dateModified" },
    { title: "Actions", key: "action" },
  ]
  return (
    <>
      <div className="card">
        <div className="card-body">
          <Table
            data={data}
            headers={headers}
            handlePagination={handlePagination}
						totalPageCount={pagination.pageCount}
						page={pagination.pageNumber}
            isLoading={loading}
            showFilters
						handleFilter={handleShowFilters}
            actions={[
              {
                name: "Approve",
                onPress: (item) => (setSelected(item), setShow(true)),
              },
              {
                name: "Disputes",
                onPress: handleDispute, //(item) => (setSelected(item), setShow(true)),
              },
            ]}
          />
        </div>
      </div>
      <Modal
        title="Approve Statement"
        show={show}
        handleClose={() => (setSelected({}), setShow(false))}
      >
        <BankStatemnt
          getData={getData}
          reconciliationPublicId={reconciliationPublicId}
          selected={selected}
          handleClose={() => (setSelected({}), setShow(false))}
        />
      </Modal>
    </>
  )
}

export default BanksStatements
