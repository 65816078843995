import React from "react";
import { Login } from "../../../components/Login";
class LoginScreen extends React.Component {
  render() {
    return (
      <div>
        <Login />
      </div>
    );
  }
}

export default LoginScreen;
