import { useEffect, useState } from "react"
import { Input, Select } from "../../../../components/Input"
import { Button } from "../../../../components/Button"
import { RECONCILIATION_ROOT_API } from "../../../../constants/api"
import toastMessage from "../../../../utils/toastMessage"
import useAxios from "../../../../hooks/useAxios"
import useHandleFormValidate from "../../../../hooks/useHandleFormValidate"

const ContactPerson = ({
	getData,
	handleClose,
	selected,
	contactPerson = {},
	setContactPerson,
	contactPersonError = {},
}) => {
	const [laoding, setLoading] = useState(false)
	const [person, setPerson] = useState(contactPerson)
	const [error, setError] = useState(contactPersonError)
	const axios = useAxios()
	const validate = useHandleFormValidate()

	useEffect(() => {
		if (selected) {
			let cp = { ...selected }
			cp.identificationType = {
				label: selected.identificationType,
				value: selected.identificationType,
			}
			setPerson(cp)
		}
	}, [])

	const handleInput = (e) => {
		const { name, value } = e.target
		if (contactPersonError && contactPersonError[name]) {
			delete contactPersonError[name]
		}
		if (setContactPerson) {
			setContactPerson((prev) => ({
				...prev,
				[name]: value,
			}))
		} else {
			setPerson((prev) => ({
				...prev,
				[name]: value,
			}))
		}
	}

	const handleSelect = (value, { name }) => {
		if (contactPersonError && contactPersonError[name]) {
			delete contactPersonError[name]
		}
		if (setContactPerson) {
			setContactPerson((prev) => ({
				...prev,
				[name]: value,
			}))
		} else {
			setPerson((prev) => ({
				...prev,
				[name]: value,
			}))
		}
	}

	const handleSubmit = async (e) => {
		e && e.preventDefault()
		const { error, valid } = await validate(contactPerson)
		if (error) setError(error)
		if (!valid) return

		setLoading(true)
		const data = {
			publicId: person.publicId,
			name: person.name,
			phoneNumber: person.phoneNumber,
			email: person.email,
			identificationType:
				person.identificationType && person.identificationType.value,
			identificationValue: person.identificationValue,
		}

		const { err, res, status } = await axios({
			method: "PUT",
			url: `${RECONCILIATION_ROOT_API}bankAccountContactPersons`,
			headers: null,
			data,
		})

		if (err) {
			if (err.messages) {
				err.messages.forEach((err) => {
					toastMessage("error", err)
				})
			} else {
				toastMessage("error", err)
			}
			setLoading(false)
		}
		if (res || (status && status === 200)) {
			toastMessage("success", `Contact person details updated successfully.`)
			setLoading(false)
			getData()
			handleClose()
		}
	}

	return (
		<div className={`${selected && "card"}`}>
			<div className="card-body ">
				<div className="d-flex justify-content-center">
					<div className={`row ${!selected && "col-md-7 col-lg-4"}`}>
						{/* <h3 className="mb-3">Personal Details</h3> */}
						<Input
							label="Full Name"
							required
							name="name"
							value={person.name}
							onChange={handleInput}
							error={error.name}
						/>
						<Input
							label="Phone Number"
							required
							placeholder="254 700 000 000"
							name="phoneNumber"
							type="number"
							value={person.phoneNumber}
							onChange={handleInput}
							error={error.phoneNumber}
						/>
						<Input
							label="Email"
							required
							name="email"
							type="email"
							value={person.email}
							onChange={handleInput}
							error={error.email}
						/>

						<Select
							name="identificationType"
							label="Identification Type"
							value={person.identificationType}
							options={[
								{ label: "NationalId", value: "NationalId" },
								{ label: "PassportNo", value: "PassportNo" },
							]}
							onChange={handleSelect}
						/>

						<Input
							label="Identification Number"
							required
							name="identificationValue"
							value={person.identificationValue}
							onChange={handleInput}
							error={error.identificationValue}
						/>
					</div>
				</div>
			</div>

			{selected && (
				<div className="card-footer d-flex justify-content-end gap-3">
					<Button
						text="Cancel"
						onPress={handleClose}
						className="btn-secondary"
					/>
					<Button
						text="Update"
						onPress={handleSubmit}
						className="btn-primary"
						isSubmitting={laoding}
					/>
				</div>
			)}
		</div>
	)
}

export default ContactPerson
