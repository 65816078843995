import { useEffect, useState } from "react"
import ViewBankAccounts from "../Accounts/ViewAll"
import { useLocation } from "react-router-dom"

const BankAccounts = () => {
	const location = useLocation()

	const [cperson] = useState(location.state && location.state.selected)
	return <ViewBankAccounts contact={cperson} />
}

export default BankAccounts
