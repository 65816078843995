import React from "react";
import { getStorage } from "../../../utils/storage";
import validateToken from "../../../utils/validation/validateToken";
import { Splash } from "../../../components/Splash";

class SplashScreen extends React.Component {
  componentDidMount = async () => {
    const userLoggedIn = await getStorage();

    setTimeout(() => {
      validateToken(userLoggedIn.token);
    }, 3000);
  };
  render() {
    return <Splash />;
  }
}

export default SplashScreen;
