import { useEffect, useState } from "react"
import { Table } from "../../../../../components/Table"
import useAxios from "../../../../../hooks/useAxios"
import { RECONCILIATION_ROOT_API } from "../../../../../constants/api"
import Filters from "./Filters"
import useApp from "../../../../../hooks/useApp"

const TransactionStatementsSummary = ({ reconciliationPublicId }) => {
	const [loading, setLoading] = useState(true)
	const [data, setData] = useState([])
	const [pagination, setPagination] = useState({})
	const axios = useAxios()
	const { setShowFilter, setFilters } = useApp()

	const [filterItems, setFilterItems] = useState({
		accountNo: "",
		paymentMode: "",
		paymentResult: "",
		transactionId: "",
		paymentServiceAccountPublicId: "",
		amount: "",
		confirmedAmount: "",
	})

	useEffect(() => {
		if (reconciliationPublicId) {
			getData()
		} else {
			setLoading(false)
			// toastMessage("error", "A reconciliation is required to view its transaction statements.")
		}
	}, [])

	const getData = async (params = {}) => {
		setLoading(true)
		let filters = { ...filterItems }
		if (filterItems.paymentMode) {
			filters.paymentMode = filterItems.paymentMode.value
		}
		if (filterItems.paymentResult) {
			filters.paymentResult = filterItems.paymentResult.value
		}
		if(filterItems.paymentServiceAccountPublicId){
			filters.paymentServiceAccountPublicId = filterItems.paymentServiceAccountPublicId.value
		}
		const { err, res } = await axios({
			method: "GET",
			url: `${RECONCILIATION_ROOT_API}reconciliations/${reconciliationPublicId}/transactionStatements/summary`,
			params: {
				reconciliationPublicId,
				...filters,
				...params
			},
			headers: null,
		})
		if (err) {
			setLoading(false)
			return
		}
		if (res) {
			setLoading(false)
			const { content, number, numberOfElements, totalElements, totalPages } =
				res
			setData(content)
			const pagination = {
				pageNumber: number + 1,
				pageCount: numberOfElements,
				totalCount: totalElements,
				pageCount: totalPages,
			}
			setPagination(pagination)
		}
	}

	const handleShowFilters = async () => {
		await setFilters(
			<Filters getData={getData} setFilterItems={setFilterItems} />
		)
		setShowFilter(true)
	}

	const handlePagination = async (page) => {
		if (!page) return
		getData({ page: page - 1 })
	}
	const headers = [
		{ title: "Date", key: "date" },
		{ title: "Total Amount", key: "totalAmount" },
		{ title: "Payment Mode", key: "paymentMode" },
		{ title: "Account Number", key: "accountNo" },
		{ title: "Payment Result", key: "paymentResult" },
		{ title: "Service Account", key: "paymentServiceAccount.name" },
		{ title: "Service No", key: "paymentServiceAccount.serviceNumber" },
		{ title: "Account Type", key: "paymentServiceAccount.accountType" },
	]
	return (
		<>
			<div className="card">
				<div className="card-body">
					<Table
						data={data}
						handlePagination={handlePagination}
						totalPageCount={pagination.pageCount}
						page={pagination.pageNumber}
						headers={headers}
						isLoading={loading}
						showFilters
						handleFilter={handleShowFilters}
					/>
				</div>
			</div>
		</>
	)
}

export default TransactionStatementsSummary
