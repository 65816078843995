import { useEffect, useState } from "react"
import { StepperItem, Steppers } from "../../../components/Steppers"
import useAxios from "../../../hooks/useAxios"
import { useNavigate } from "react-router-dom"
import Saccos from "./Forms/Saccos"
import BusOwnerDetails from "./Forms/BusOwnerDetails"
import ContactPerson from "./Forms/ContactPerson"
import Documents from "./Forms/Documents"
import BankDetails from "./Forms/BankDetails"
import useHandleFormValidate from "../../../hooks/useHandleFormValidate"
import { RECONCILIATION_ROOT_API } from "../../../constants/api"
import toastMessage from "../../../utils/toastMessage"

const AddBusOwner = ({ submittedSacco, selected, setSelected }) => {
	const [step, setStep] = useState(0)

	const axios = useAxios()
	const validate = useHandleFormValidate()
	const navigate = useNavigate()

	const [serviceAccount, setAccount] = useState({
		serviceAccount: "",
		clientId: "",
	})
	const [accError, setAccError] = useState({})

	const [details, setDetails] = useState({
		name: serviceAccount.clientId ? serviceAccount.clientId.name : "",
		accountType: { label: "BusOwner", value: "BusOwner" },
		kraPin: "",
		notes: "",
	})
	const [detailsErr, setDetailsErr] = useState({})

	const [contactPerson, setContactPerson] = useState({
		name: "",
		email: "",
		phoneNumber: "",
		nationalId: "",
	})
	const [contactPersonErr, setContactPersonErr] = useState({})

	const [bank, setBank] = useState({ bankCode: "", accountNumber: "" })
	const [bankErr, setBankErr] = useState({})

	const [submitting, setSubmitting] = useState(false)
	const [submit, setSubmit] = useState(false)

	useEffect(() => {
		if (selected) {
			if (step === 0) {
				setStep(1)
			}
			if (selected.contactPerson) {
				setContactPerson(selected.contactPerson)
			}
			let dt = {
				name: selected.name,
				accountType: { label: "BusOwner", value: "BusOwner" },
				kraPin: selected.kraPin,
				notes: selected.notes,
			}
			setDetails(dt)

			let sa = {
				label: selected.paymentServiceAccountPublicId,
				value: selected.paymentServiceAccountPublicId,
			}
			setAccount(sa)
		}
	}, [selected])

	const handleNext = async (current) => {
		if (current === 0) {
			if (selected) {
				setStep(current + 1)
				return
			}
			const { error, valid } = await validate(serviceAccount)
			if (error) setAccError(error)
			if (valid) setStep(current + 1)
		}
		if (current === 1) {
			let toValidate = { ...details }
			delete toValidate.notes
			const { error, valid } = await validate(toValidate)
			if (error) setDetailsErr(error)
			if (valid) setStep(current + 1)
			setSubmit(true)
		}
		if (current === 2) {
			const { error, valid } = await validate(contactPerson)
			if (error) setContactPersonErr(error)
			if (valid) setStep(current + 1)
		}
		if (current === 3) {
			setSubmit(true)
			setStep(current + 1)
		}
	}

	const handleSubmit = async (e) => {
		e && e.preventDefault()
		if (step === 2) {
			submitBusOwner()
		}
		if (step === 4) {
			submitAccount()
		}
	}

	const submitBusOwner = async () => {
		setSubmitting(true)
		let data = {
			accountType: details.accountType && details.accountType.value,
			name: details.name,
			kraPin: details.kraPin,
			contactPerson: {
				name: contactPerson.name,
				email: contactPerson.email,
				phoneNumber: contactPerson.phoneNumber,
				nationalId: contactPerson.nationalId,
			},
			notes: details.notes,
		}
		let publicId = {}
		if (selected && selected.publicId) {
			publicId = {
				publicId: selected.publicId,
			}
		}

		if (!selected || !selected.publicId) {
			data.paymentServiceAccountPublicId =
				serviceAccount.serviceAccount && serviceAccount.serviceAccount.value
			data.clientId = serviceAccount.clientId && serviceAccount.clientId.value
		}

		const { err, res, status } = await axios({
			method: selected && selected.publicId ? "PUT" : "POST",
			url: `${RECONCILIATION_ROOT_API}paymentServices`,
			headers: null,
			data: {
				...publicId,
				...data,
			},
		})
		if (err) {
			setSubmitting(false)
			if (err.messages && err.messages[0]) {
				toastMessage("error", err.messages[0])
			}
			return
		}
		if (res || (status && status === 200)) {
			setSelected(res)
			toastMessage(
				"success",
				`Bus Owner Account ${selected ? "updated" : "added"} successfully.`
			)
			setSubmitting(false)
			setSubmit(false)
		}
	}

	const submitAccount = async () => {
		let payload = {
			bankCode: bank.bankCode,
			accountNumber: bank.accountNumber,
		}
		if (bank.publicId) {
			payload.publicId = bank.publicId
		}
		const { error, valid } = await validate(payload)
		if (error) setBankErr(error)
		if (!valid) return

		setSubmitting(true)
		const { err, res, status } = await axios({
			method: bank && bank.publicId ? "PUT" : "POST",
			url: `${RECONCILIATION_ROOT_API}paymentServices/${selected.publicId}/bankDetails`,
			headers: null,
			data: payload,
		})

		if (err) {
			if (err.messages) {
				err.messages.forEach((err) => {
					toastMessage("error", err)
				})
			} else {
				toastMessage("error", err)
			}
		}
		if (res || (status && status === 200)) {
			toastMessage(
				"success",
				`Bank details ${bank.publicId ? "updated" : "added"} successfully.`
			)
			setBank(res)
			setSubmit(false)
			setSubmitting(false)
		}
	}

	return (
		<div className="card">
			<div className="card-body">
				<Steppers
					currentStep={step}
					close={() => navigate("/dashboard/service_accounts")}
				>
					<span>Sacco Service Account</span>
					<span>Account details</span>
					<span>Contact Person</span>
					<span>Documents</span>
					<span>Bank details</span>
				</Steppers>

				<StepperItem
					handleNext={handleNext}
					stepsCount={5}
					submitting={submitting}
					handleSubmit={handleSubmit}
					setSubmit={submit}
					handleSetSubmit={setSubmit}
					step={step}
					setStep={setStep}
				>
					{step === 0 ? (
						<Saccos
							submittedSacco={submittedSacco}
							serviceAccount={serviceAccount}
							setDetails={setDetails}
							setAccount={setAccount}
							accError={accError}
						/>
					) : step === 1 ? (
						<BusOwnerDetails
							details={details}
							setDetails={setDetails}
							detailsErr={detailsErr}
						/>
					) : step === 2 ? (
						<ContactPerson
							contactPerson={contactPerson}
							setContactPerson={setContactPerson}
							contactPersonErr={contactPersonErr}
						/>
					) : step === 3 ? (
						<Documents selected={selected} />
					) : (
						<BankDetails
							bank={bank}
							setBank={setBank}
							bankErr={bankErr}
							selected={selected}
						/>
					)}
				</StepperItem>
			</div>
		</div>
	)
}

export default AddBusOwner
