import { useState } from "react"
import { Button } from "../../../../../components/Button"
import { Input, Select } from "../../../../../components/Input"
import useHandleFormValidate from "../../../../../hooks/useHandleFormValidate"
import useAxios from "../../../../../hooks/useAxios"
import { RECONCILIATION_ROOT_API } from "../../../../../constants/api"
import { getStorage } from "../../../../../utils/storage"
import toastMessage from "../../../../../utils/toastMessage"

const Add = ({ handleClose, getData }) => {
  const [add, setAdd] = useState({
    transactionDate: "",
    // documentType: "",
    // file: "",
  })
  const [error, setError] = useState({})
  const [loading, setLoading] = useState(false)
  const validateForm = useHandleFormValidate()
  const axios = useAxios()

  const handleInput = (e) => {
    const { name, value } = e.target
    if (error[name]) {
      delete error[name]
    }
    setAdd((prev) => ({
      ...prev,
      [name]: value,
    }))
  }
  const handleSelect = ({ value }, { name }) => {
    if (error[name]) {
      delete error[name]
    }
    setAdd((prev) => ({
      ...prev,
      [name]: value,
    }))
  }
  const handleFile = (e) => {
    setAdd((prev) => ({
      ...prev,
      file: e.target.files[0],
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const { error, valid } = await validateForm(add)
    setError(error)
    if (!valid) return
    setLoading(true)

    const user = await getStorage()
    const { err, res } = await axios({
      method: "POST",
      url: `${RECONCILIATION_ROOT_API}reconciliations`,
      headers: null,
      data: {
        transactionDate: add.transactionDate,
        initiator: user && user.username,
      },
    })
    if (err) {
      if (err.messages) {
        toastMessage("error", err.messages[0])
      }
      setLoading(false)
      return
    }
    if (res) {
      // handleFileUpload(res)
      getData()
      setLoading(false)
      toastMessage(
        "success",
        "A new reconciliation has been created sucessfully."
      )
      handleClose()
    }
  }

  const handleFileUpload = async (recon) => {
    const formData = new FormData()
    formData.append("file", add.file)

    const { err, res } = await axios({
      method: "POST",
      url: `${RECONCILIATION_ROOT_API}reconciliations/${recon.publicId}/document`,
      headers: null,
      data: formData,
      params: {
        reconciliationPublicId: recon.publicId,
        documentType: add.documentType,
      },
    })
    if (err) {
      setLoading(false)
      if (err.messages) {
        toastMessage("error", err.messages[0])
      }
      return
    }
    if (res) {
      setLoading(false)
      toastMessage(
        "success",
        "A new reconciliation has been created sucessfully."
      )
      handleClose()
    }
  }

  return (
    <div className="card">
      <div className="card-body">
        <form>
          <Input
            label="Transaction Date"
            required
            name="transactionDate"
            value={add.transactionDate}
            error={error.transactionDate}
            onChange={handleInput}
            type="date"
          />
          {/* <Select
            required
            label="Document Type"
            name="documentType"
            // value={add.documentType}
            onChange={handleSelect}
            error={error.documentType}
            options={[
              { label: "Bank Statments", value: "BankStatments" },
              { label: "M-Pesa Statments", value: "MpesaTransactions" },
              { label: "Token Statments", value: "TokenTransactions" },
            ]}
          /> */}
          {/* <Input
            label="CSV File"
            required
            onChange={handleFile}
            name="file"
            error={error.file}
            type="file"
          /> */}
        </form>
      </div>
      <div className="card-footer d-flex justify-content-end gap-3">
        <Button onPress={handleClose} className="btn-secondary" text="Cancel" />
        <Button
          onPress={handleSubmit}
          isSubmitting={loading}
          className="btn-primary"
          text="Submit"
        />
      </div>
    </div>
  )
}

export default Add
