import { Input } from "../../../../../components/Input"
import { Button } from "../../../../../components/Button"

export const Filters = (props) => {
  const {
    filterBy = {},
    handleInput,
    handleApply,
    handleReset,
    isSubmitting,
  } = props
  return (
    <div>
      <div className="modal-body">
        <div className="date-range">
          <Input
            name="startDate"
            error={filterBy.errors && filterBy.errors.startDate}
            onChange={handleInput}
            type="date"
            required
            value={filterBy.startDate}
            label="Start date:"
          />

          <Input
            name="endDate"
            error={filterBy.errors && filterBy.errors.endDate}
            onChange={handleInput}
            type="date"
            required
            value={filterBy.endDate}
            label="End date:"
          />
        </div>
      </div>
      <div className="modal-footer">
        <Button onPress={handleReset} text="Reset" className="btn-secondary" />
        <Button
          submitting={isSubmitting}
          onPress={handleApply}
          text="Apply"
          className="btn-primary"
        />
      </div>
    </div>
  )
}
