import { useEffect, useState } from "react"
import { Tabs } from "../../../components/Tabs"
import Documents from "./Forms/Documents"
import Account from "./Account/Details"
import { useParams } from "react-router-dom"
import Vehicles from "./Account/Vehicles"
import Signatories from "./Account/Signatories"
import Shareholders from "./Account/Shareholders"

const ViewSingle = () => {
	const [active, setActive] = useState()

	const params = useParams()
	const [selected, setSelected] = useState({ publicId: params.id })

	function renderDocuments() {
		return (
			<div className="card">
				<Documents selected={selected} />
			</div>
		)
	}

	function renderAccount() {
		return <Account selected={selected} />
	}

	function renderVehicles() {
		return <Vehicles selected={selected} />
	}

	function renderSignatories(){
		return <Signatories selected={selected} />
	}

	function renderShareholders(){
		return <Shareholders selected={selected} />
	}

	let options = [
		{
			title: "Account Info",
			data: renderAccount(),
		},
		{
			title: "Documents",
			data: renderDocuments(),
		},
		{
			title: "Vehicles",
			data: renderVehicles(),
		},
		{
			title: "Signatories",
			data: renderSignatories(),
		},
		{
			title: "Shareholders",
			data: renderShareholders(),
		},
	]
	return <Tabs options={options} activeTab={active} />
}

export default ViewSingle
