import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Navbar } from "../../components/Navbar"
import { Sidebar } from "../../components/Sidebar"
import { clearStorage, getStorage } from "../../utils/storage"
import validateToken from "../../utils/validation/validateToken"
import { onToggle } from "../../actions/ToggleSidebar"
import { Outlet, useLocation, useNavigate } from "react-router"

const DashboardLayout = (props) => {
	const [user, setUser] = useState({})
	const [title, setTitle] = useState("")

	const location = useLocation()
	const navigate = useNavigate()

	useEffect(() => {
		const init = async () => {
			const user = await getStorage()
			if (!validateToken(user)) {
				navigate("/login")
				return
			} else {
				setUser(user)
			}
			let pathname = location.pathname
			let title = ""
			if (pathname !== "") {
				const last_t = pathname.split("/")
				title = last_t[last_t.length - 1].replaceAll("_", " ")
			}
			setTitle(title)
			const { innerWidth } = window
			if (innerWidth < 750) {
				props.dispatch(onToggle(false))
			}
			if (innerWidth < 750) {
				props.dispatch(onToggle(false))
			}
		}
		init()

		window.addEventListener("resize", handleResize)
		return () => {
			window.removeEventListener("resize", handleResize)
		}
	}, [location.pathname])

	function handleResize() {
		const { innerWidth } = window

		if (innerWidth > 750) {
			props.dispatch(onToggle(true))
		} else {
			props.dispatch(onToggle(false))
		}
	}

	async function onLogout() {
		await clearStorage()
		props.dispatch({ type: "ON_USER_LOGOUT" })

		navigate( "/login")
	}
	// console.log("====================================")
	// console.log(user)
	// console.log("====================================")

	return (
		<div className="main-layout-dashboard">
			<Sidebar location={location} {...props} />
			<div className="dash-container-wrapper">
				<Navbar onLogout={onLogout} title={title} user={user} />
				<div className="dash-content">{props.children}</div>
			</div>
		</div>
	)
}

export default connect(null)(DashboardLayout)
