import { Button } from "../Button"

const StepperItem = ({
	handleNext,
	children,
	setStep,
	step,
	setSubmit,
	handleSetSubmit,
	submitting,
	handleSubmit,
	stepsCount = 0,
}) => {
	return (
		<div
			style={{
				minHeight: "62vh",
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
			}}
		>
			<div className="w-100">{children}</div>
			<div className="card-footer stepper-next d-flex justify-content-end gap-3">
				<Button
					onPress={() => (setStep(step - 1), handleSetSubmit? handleSetSubmit(false): null)}
					disabled={step < 1||submitting}
					text="Previous"
					className="btn-secondary"
				/>
				{setSubmit ? (
					<Button
						onPress={handleSubmit}
						isSubmitting={submitting}
						text={"Submit"}
						className="btn-primary"
					/>
				) : (
					<Button
						onPress={() => (handleNext ? handleNext(step) : setStep(step + 1))}
						disabled={step === stepsCount - 1}
						text={"Next"}
						className="btn-primary"
					/>
				)}
			</div>
		</div>
	)
}

export default StepperItem
