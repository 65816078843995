import { useState } from "react"
import { Table } from "../../../../components/Table"

const Signatories = () => {
	const [data, setData] = useState([])
	const [loading, setLoading] = useState(false)
	const [pagination, setPagination] = useState({})

	const headers = [
        { title: "Name", key: "name" },
        { title: "National ID", key: "nationalId" },
        { title: "KRA", key: "kraPin" },
        { title: "Designation", key: "designation" },
        { title: "Email", key: "email" },
        { title: "Phone", key: "phoneNumber" },
        { title: "DOB", key: "dob" },
        { title: "Address", key: "physicalAddress" },
        { title: "Nationality", key: "nationality" },
        { title: "Date Created", key: "dateCreated" },
        { title: "Actions", key: "action" },
    ]
	return (
		<div className="card">
			<div className="card-body">
				<Table
					headers={headers}
					data={data}
					isLoading={loading}
					showAdd
					addButtonText="Add Signatory"
				/>
			</div>
		</div>
	)
}

export default Signatories
