import React, { useEffect, useState } from "react"
import { Table } from "../../../../../components/Table"
import toastMessage from "../../../../../utils/toastMessage"
import useAxios from "../../../../../hooks/useAxios"
import { RECONCILIATION_ROOT_API } from "../../../../../constants/api"
import { Modal } from "../../../../../components/Modal"
import Settlement from "./Settlement"

const Settlements = ({
	reconciliationPublicId,
	setStatementActive,
	setViewStReportDispute,
}) => {
	const [loading, setLoading] = useState(true)
	const [data, setData] = useState([])
	const [pagination, setPagination] = useState({})
	const [selected, setSelected] = useState({})
	const [show, setShow] = useState(false)
	const [submit, setSubmit] = useState(false)
	const [batch, setBatch] = useState([])
	const axios = useAxios()

	useEffect(() => {
		if (reconciliationPublicId) {
			getData()
		} else {
			setLoading(false)
		}
	}, [])

	const getData = async (params = {}) => {
		setLoading(true)
		const { err, res } = await axios({
			method: "GET",
			url: `${RECONCILIATION_ROOT_API}reconciliations/${reconciliationPublicId}/settlement`,
			params: {
				reconciliationPublicId,
				...params,
			},
			headers: null,
		})
		if (err) {
			setLoading(false)
			return
		}
		if (res) {
			setLoading(false)
			const { content, number, numberOfElements, totalElements, totalPages } =
				res
			setData(content)
			const pagination = {
				pageNumber: number + 1,
				pageCount: numberOfElements,
				totalCount: totalElements,
				pageCount: totalPages,
			}
			setPagination(pagination)
		}
	}

	const handleSelect = (item) => {
		if (!item) return
		if (batch.includes(item.publicId)) {
			let btch = batch.filter((entry) => entry !== item.publicId)
			setBatch(btch)
			return
		}
		setBatch((prev) => [...prev, item.publicId])
	}

	const handleSubmitBatch = async () => {
		if (batch.length === 0) return

		setSubmit(true)
		const { err, res } = await axios({
			method: "POST",
			url: `${RECONCILIATION_ROOT_API}reconciliations/${reconciliationPublicId}/settlementBatch`,
			params: {
				reconciliationPublicId,
			},
			data: {
				settlementPublicIds: batch,
			},
			headers: null,
		})
		if (err) {
			setSubmit(false)
			if (err.messages) {
				err.messages.forEach((err) => {
					toastMessage("error", err)
				})
			}
			return
		}
		if (res) {
			setSubmit(false)
			getData()
			toastMessage("success", "Batch Approval successfully.")
			setBatch([])
		}
	}
	const handleDispute = async (item) => {
		await setViewStReportDispute(item)
		await setStatementActive("disputes")
		setStatementActive()
	}
	const handlePagination = async (page) => {
		if (!page) return
		getData({ page: page - 1 })
	}

	const headers = [
		{ title: "Select", key: "selectAll", onSelect: handleSelect },
		{ title: "Account Number", key: "accountNo" },
		{ title: "Client", key: "paymentServiceAccount.name" },
		{ title: "Account Type", key: "paymentServiceAccount.accountType" },
		{ title: "Service No", key: "paymentServiceAccount.serviceNumber" },
		{ title: "Payment Mode", key: "paymentMode" },
		{ title: "Commission Fee", key: "commissionFees" },
		{ title: "Amount", key: "totalAmount" },
		{ title: "Confirmed Amount", key: "totalConfirmedAmount" },
		{ title: "Created At", key: "dateCreated" },
		{ title: "Modified At", key: "dateModified" },
		{ title: "Action", key: "action" },
	]
	return (
		<>
			<div className="card">
				<div className="card-body">
					<Table
						rowPress={(item) => (setSelected(item), setShow(true))}
						data={data}
						headers={headers}
						isLoading={loading}
						handlePagination={handlePagination}
						totalPageCount={pagination.pageCount}
						page={pagination.pageNumber}
						filters={[
							{
								title: "Approve",
								className: "btn-primary",
								loading: submit,
								disabled: batch.length <= 0,
								onPress: handleSubmitBatch,
								default_key: "label",
								icon: "bx",
							},
						]}
						actions={[
							{
								name: "Disputes",
								onPress: handleDispute,
							},
						]}
					/>
				</div>
			</div>
			<Modal
				show={show}
				title="Settlement Information"
				// size="lg"
				handleClose={() => (setSelected({}), setShow(false))}
			>
				<Settlement
					selected={selected}
					handleClose={() => (setSelected({}), setShow(false))}
				/>
			</Modal>
		</>
	)
}

export default Settlements
