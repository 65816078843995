import { Input } from "../../../../components/Input"

const BusOwnerDetails = ({ details={}, setDetails, detailsErr={} }) => {
	const handleInput = (e) => {
		const { name, value } = e.target
		if (detailsErr[name]) {
			delete detailsErr[name]
		}
		setDetails((prev) => ({
			...prev,
			[name]: value,
		}))
	}

	return (
		<div className="card-body ">
			<div className="d-flex justify-content-center">
				<div className="col-md-9 col-lg-6 row">
					<Input
						label="Name"
						required
						name="name"
						value={details.name}
						error={detailsErr.name}
						onChange={handleInput}
					/>
					<div className="col">
						<Input
							type="text"
							label="KRA PIN"
							required
							name="kraPin"
							value={details.kraPin}
							error={detailsErr.kraPin}
							onChange={handleInput}
						/>
					</div>
					<Input
						type="textarea"
						label="Notes"
						name="notes"
						value={details.notes}
						error={detailsErr.notes}
						onChange={handleInput}
					/>
				</div>
			</div>
		</div>
	)
}

export default BusOwnerDetails
