import { useEffect, useState } from "react"
import { Table } from "../../../../../components/Table"
import useAxios from "../../../../../hooks/useAxios"
import { RECONCILIATION_ROOT_API } from "../../../../../constants/api"
import { Modal } from "../../../../../components/Modal"
import Dispute from "./Dispute"
import Comments from "./Comments"
import { Spinner } from "react-bootstrap"
import toastMessage from "../../../../../utils/toastMessage"

const Disputes = ({ transActive, entity = {} }) => {
	const [loading, setLoading] = useState(true)
	const [data, setData] = useState([])
	const [pagination, setPagination] = useState({})
	const [selected, setSelected] = useState({})
	const [show, setShow] = useState(false)
	const [showComments, setShowComments] = useState(false)
	const [resolving, setResolving] = useState(false)
	const [passed] = useState(entity)

	const axios = useAxios()

	useEffect(() => {
		if (entity && entity.publicId) {
			getData()
		} else {
			setLoading(false)
		}
	}, [transActive])

	const getData = async (params = {}) => {
		setLoading(true)
		const { err, res } = await axios({
			method: "GET",
			url: `${RECONCILIATION_ROOT_API}disputes`,
			params: {
				entityPublicId: entity.publicId,
				...params,
			},
			headers: null,
		})
		if (err) {
			setLoading(false)
			return
		}
		if (res) {
			setLoading(false)
			const { content, number, numberOfElements, totalElements, totalPages } =
				res
			setData(content)
			const pagination = {
				pageNumber: number + 1,
				pageCount: numberOfElements,
				totalCount: totalElements,
				pageCount: totalPages,
			}
			setPagination(pagination)
		}
	}

	const handleResolve = async (item) => {
		if (!item.publicId) return
		setResolving(true)
		const { err, res } = await axios({
			method: "PATCH",
			url: `${RECONCILIATION_ROOT_API}disputes/${item.publicId}/actions`,
			params: { disputePublicId: item.publicId },
			data: { actionType: "Resolve" },
			headers: null,
		})
		if (err) {
			setResolving(false)
			if (err.messages) {
				toastMessage("error", err.messages[0])
			}
			return
		}
		if (res) {
			setResolving(false)
			getData()
			toastMessage("success", "Dispute resolved successfully")
		}
	}

	const handlePagination = async (page) => {
		if (!page) return
		getData({ page: page - 1 })
	}

	const headers = [
		{ title: "Title", key: "title" },
		{ title: "Description", key: "description" },
		{ title: "Status", key: "status" },
		{ title: "Created At", key: "dateCreated" },
		{ title: "Modified At", key: "dateModified" },
		{ title: "Actions", key: "action" },
	]

	return (
		<>
			<div className="card">
				<div className="card-body">
					<Table
						showAdd
						addButtonText="Dispute"
						handleAddPressed={() => setShow(true)}
						handlePagination={handlePagination}
						totalPageCount={pagination.pageCount}
						page={pagination.pageNumber}
						data={data}
						headers={headers}
						isLoading={loading}
						actions={[
							{
								name: "Comments",
								onPress: (item) => (setSelected(item), setShowComments(true)),
							},
							{
								name: resolving ? (
									<Spinner animation="border" variant="danger" size="sm" />
								) : (
									"Resolve"
								),
								onPress: handleResolve,
							},
						]}
					/>
				</div>
			</div>
			<Modal title="Add Dispute" show={show} handleClose={() => setShow(false)}>
				<Dispute
					getData={getData}
					entity={entity}
					handleClose={() => setShow(false)}
				/>
			</Modal>
			<Modal
				title="Dispute Comments"
				show={showComments}
				handleClose={() => (setShowComments(false), setSelected({}))}
			>
				<Comments
					getData={getData}
					entity={entity}
					selected={selected}
					handleClose={() => (setShowComments(false), setSelected({}))}
				/>
			</Modal>
		</>
	)
}

export default Disputes
