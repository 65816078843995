import { Tabs } from "../../../../../components/Tabs"
import { Input } from "../../../../../components/Input"
import { Table } from "../../../../../components/Table"

const Settlement = ({ selected }) => {
	console.log({ selected })

	const { reconciliation, paymentServiceAccount, activities } = selected
	const handleInput = () => {}

	function renderReconciliation() {
		return (
			<form className="card">
				<div className="card-body">
					<Input
						label="Transaction Date"
						value={reconciliation && reconciliation.transactionDate}
						onChange={handleInput}
						disabled
					/>
					<Input
						label="Date Created"
						value={reconciliation && reconciliation.dateCreated}
						onChange={handleInput}
						disabled
					/>
					<Input
						label="Date Modified"
						value={reconciliation && reconciliation.dateModified}
						onChange={handleInput}
						disabled
					/>
				</div>
			</form>
		)
	}

	function renderClient() {
		return (
			<form className="card">
				<div className="card-body">
					<Input
						label="Client Name"
						value={paymentServiceAccount && paymentServiceAccount.name}
						onChange={handleInput}
						disabled
					/>
					<Input
						label="Service Number"
						value={paymentServiceAccount && paymentServiceAccount.serviceNumber}
						onChange={handleInput}
						disabled
					/>
					<Input
						label="Account Type"
						value={paymentServiceAccount && paymentServiceAccount.accountType}
						onChange={handleInput}
						disabled
					/>
					<Input
						label="Date Created"
						value={paymentServiceAccount && paymentServiceAccount.dateCreated}
						onChange={handleInput}
						disabled
					/>
					<Input
						label="Date Modified"
						value={paymentServiceAccount && paymentServiceAccount.dateModified}
						onChange={handleInput}
						disabled
					/>
				</div>
			</form>
		)
	}

	function renderActivies() {
		return (
			<div className="card">
				<div className="card-body">
					<Table
						headers={[
							{ title: "Description", key: "description" },
							{ title: "Date Created", key: "dateCreated" },
						]}
						data={activities || []}
					/>
				</div>
			</div>
		)
	}

	const options = [
		{
			title: "Reconciliation",
			data: renderReconciliation(),
		},
		{
			title: "Client Account",
			data: renderClient(),
		},
		{
			title: "Activities",
			data: renderActivies(),
		},
	]
	return <Tabs options={options} />
}

export default Settlement

//authorized, resolved for settllements

///disputes for transanctions, bank statements, mpesa na settlements

//approved for bank statements
