import { Input } from "../../../../components/Input"

const Address = ({ address = {}, setAddress, addressErr = {} }) => {
	const handleInput = (e) => {
		const { name, value } = e.target
		if (addressErr[name]) {
			delete addressErr[name]
		}
		setAddress((prev) => ({
			...prev,
			[name]: value,
		}))
	}
	return (
		<div className="card-body ">
			<div className="d-flex justify-content-center">
				<div className="col-md-9 col-lg-6 row">
					<div className="col-md-6">
						<Input
							label="Country"
							required
							name="country"
							value={address.country}
							error={addressErr.country}
							onChange={handleInput}
						/>
					</div>
					<div className="col-md-6">
						<Input
							label="City"
							required
							name="city"
							value={address.city}
							error={addressErr.city}
							onChange={handleInput}
						/>
					</div>
					<div className="col-md-6">
						<Input
							label="Physical Location"
							required
							name="physicalLocation"
							value={address.physicalLocation}
							error={addressErr.physicalLocation}
							onChange={handleInput}
						/>
					</div>
					<div className="col-md-6">
						<Input
							label="Building Name"
							required
							name="building"
							value={address.building}
							error={addressErr.building}
							onChange={handleInput}
						/>
					</div>
					<div className="col-md-6">
						<Input
							label="Road Name"
							required
							name="roadName"
							value={address.roadName}
							error={addressErr.roadName}
							onChange={handleInput}
						/>
					</div>
					<div className="col-md-6">
						<Input
							label="Postal Address"
							required
							name="postalAddress"
							value={address.postalAddress}
							error={addressErr.postalAddress}
							onChange={handleInput}
						/>
					</div>
					<div className="col-md-6">
						<Input
							label="Postal Code"
							required
							name="postalCode"
							value={address.postalCode}
							error={addressErr.postalCode}
							onChange={handleInput}
						/>
					</div>
					<div className="col-md-6">
						<Input
							label="Postal Town"
							required
							name="postalTown"
							value={address.postalTown}
							error={addressErr.postalTown}
							onChange={handleInput}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Address
