import React, { useEffect, useState } from "react"
import { getStorage } from "../../../utils/storage"

const Home = () => {
	const [user, setUser] = useState({})

	useEffect(() => {
		const init = async () => {
			const u = await getStorage()
			setUser(u)
		}
		init()
	}, [])
	return (
		<div className="card">
			<div className="card-body">
				<p>Welcome to MobiTill </p>
				<h3>{user.name}</h3>
			</div>
		</div>
	)
}

export default Home
