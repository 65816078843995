import { Outlet, Route, Routes } from "react-router-dom"

import Accounts from "./Accounts"
import ViewAllBankAccounts from "./Accounts/ViewAll"
import AddBankAccount from "./Accounts/Add"

import ContactPerson from "./ContactPersons"
import ViewAllContactPersons from "./ContactPersons/ViewAll"
import ViewContactPersonAccounts from "./ContactPersons/BankAccounts"

import ViewDocuments from "./Documents/ViewAll"

const BankAccounts = () => {
	return (
		<>
			<Routes>
				<Route path="" element={<ViewAllBankAccounts />} />

				<Route path="accounts_details" element={<Accounts />}>
					<Route path="" element={<ViewAllBankAccounts />} />
					<Route path="add" element={<AddBankAccount />} />
					<Route path="documents/:name" element={<ViewDocuments />} />
				</Route>

				<Route path="contact_persons" element={<ContactPerson />}>
					<Route path="" element={<ViewAllContactPersons />} />
					<Route
						path="accounts/:name"
						element={<ViewContactPersonAccounts />}
					/>
				</Route>
			</Routes>

			<Outlet />
		</>
	)
}

export default BankAccounts
