import Async, { useAsync } from "react-select/async"
import "./styles.css"

const CustomAsyncSelect = (props) => {
	return (
		<div className={"form-group"}>
			{props.label && <label>{props.label}</label>}{" "}
			{props.required && <span className="required">*</span>}
			<Async
				defaultOptions={props.defaultOptions}
				defaultValue={props.defaultValue}
				cacheOptions
				classNamePrefix={"selector"}
				isLoading={props.isLoading}
				onMenuScrollToBottom={props.onScrollBottom}
				className={`${props.error && "is-invalid"}`}
				loadOptions={props.loadOptions}
				name={props.name}
				placeholder={props.placeholder}
				isDisabled={props.isDisabled}
				onChange={props.onChange}
				inputValue={props.inputValue}
				onInputChange={props.onInputChange}
				value={props.value}
				onFocus={props.onFocus}
				isMulti={props.isMulti}
			/>
			{props.error && <div className="text-danger">{props.error}</div>}
		</div>
	)
}

export default CustomAsyncSelect
