import { useEffect, useState } from "react"
import { Select } from "../../../../components/Input"
import {
	EPESI_API,
	EPESI_DEV_API,
	EPESI_DEV_TOKEN,
	EPESI_TOKEN,
	RECONCILIATION_ROOT_API,
} from "../../../../constants/api"
import useAxios from "../../../../hooks/useAxios"
import toastMessage from "../../../../utils/toastMessage"

const Saccos = ({
	serviceAccount,
	setAccount,
	accError = {},
	setDetails,
	submittedSacco,
}) => {
	const [loading, setLoading] = useState(true)
	const [saccos, setSaccos] = useState([])

	const [clients, setClients] = useState([])
	const [loadingClients, setLoadingClients] = useState(false)

	const axios = useAxios()

	useEffect(() => {
		getServiceAccounts()
	}, [submittedSacco])

	const handleSelect = (value, { name }) => {
		if (accError[name]) {
			delete accError[name]
		}
		setAccount((prev) => ({
			...prev,
			[name]: value,
		}))
		if(name==="serviceAccount"){
			// getClient({applicationId:"b930f956-3538-4dc5-9a30-d8f093a1cc18"})
			getClient(value)
		}
		if(name==="clientId"){
			setDetails(prev=>({
				...prev,
				name: value.label
			}))
		}
	}

	const getServiceAccounts = async () => {
		const { err, res } = await axios({
			method: "GET",
			url: `${RECONCILIATION_ROOT_API}paymentServices`,
			headers: {},
			params: { accountType: "Sacco" },
		})

		if (err) {
			setLoading(false)
			if (err.messages && err.messages[0]) {
				toastMessage("error", err.messages[0])
			}
			return
		}
		if (res) {
			const { content } = res
			let data = content.map((item) => {
				let entry = { ...item }
				entry.value = item.publicId
				entry.label = item.name
				return entry
			})
			setLoading(false)
			setSaccos(data)
		}
	}

	const getClient = async (org) => {
		if(!org||!org.applicationId) return
		setLoadingClients(true)
		const { err, res } = await axios({
			method: "GET",
			url: `${EPESI_API}/clients`,
			headers: {
				Permission: org.applicationId,
				Authorization: `Bearer ${EPESI_TOKEN}`
			},
			params: {
				appid: org.applicationId
			},
		})

		if (err) {
			setLoadingClients(false)
			if (err.errors && err.errors[0]) {
				// toastMessage("error", err.errors[0])
			}
			return
		}
		if (res) {
			const { data } = res
			setLoadingClients(false)
			if(!data||!Array.isArray(data)) return
			let items = data.map((item) => {
				let entry = { ...item }
				entry.value = item._id
				entry.label = item.name
				return entry
			})
			setLoading(false)
			setClients(items)
		}
	}

	return (
		<div className="card-body ">
			<div className="d-flex w-100 flex-column align-items-center">
				<div className="col-md-9 col-lg-6 row">
					<div className="col-sm-12">
						<h3 className="mb-3">Select Sacco Service Account</h3>
						<Select
							label="Service Account:"
							required
							isLoading={loading}
							name="serviceAccount"
							options={saccos}
							value={serviceAccount.serviceAccount}
							onChange={handleSelect}
							error={accError.serviceAccount}
						/>
					</div>
					<div className="col-sm-12">
						<h3 className="mb-3">Select Client/Bus Owner</h3>
						<Select
							label="Client:"
							required
							isLoading={loadingClients}
							name="clientId"
							options={clients}
							value={serviceAccount.clientId}
							onChange={handleSelect}
							error={accError.clientId}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Saccos
