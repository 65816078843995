import { Input } from "../../../../components/Input"

const AccountDetails = ({ details = {}, setDetails, detailsErr = {} }) => {
	const handleInput = (e) => {
		const { name, value } = e.target
		if (detailsErr[name]) {
			delete detailsErr[name]
		}
		setDetails((prev) => ({
			...prev,
			[name]: value,
		}))
	}

	const handleSelect = (value, { name }) => {
		if (detailsErr[name]) {
			delete detailsErr[name]
		}
		setDetails((prev) => ({
			...prev,
			[name]: value,
		}))
	}

	return (
		<div className="card-body ">
			<div className="d-flex justify-content-center">
				<div className="col-md-9 col-lg-6 row">
					<Input
						label="Account Name"
						required
						name="name"
						value={details.name}
						error={detailsErr.name}
						onChange={handleInput}
					/>
					<div className="col-md-6">
						<Input
							type="text"
							label="KRA PIN"
							required
							name="kraPin"
							value={details.kraPin}
							error={detailsErr.kraPin}
							onChange={handleInput}
						/>
					</div>
					<div className="col-md-6">
						<Input
							label="Business Nature"
							required
							name="businessNature"
							value={details.businessNature}
							error={detailsErr.businessNature}
							onChange={handleInput}
						/>
					</div>
					{/* <div className="col-md-6">
						<Select
							options={[
								{ label: "Sacco", value: "Sacco" },
								{ label: "BusOwner", value: "BusOwner" },
							]}
							label="Account Type"
							required
							name="accountType"
							isDisabled
							error={detailsErr.accountType}
							value={details.accountType}
							onChange={handleSelect}
						/>
					</div> */}
					<div className="col-md-6">
						<Input
							label="Registration Number"
							name="registrationNumber"
							value={details.registrationNumber}
							onChange={handleInput}
							error={detailsErr.registrationNumber}
							required
						/>
					</div>
					<div className="col-md-6">
						<Input
							type="date"
							label="Registration Date"
							name="registrationDate"
							value={details.registrationDate}
							onChange={handleInput}
							error={detailsErr.registrationDate}
							required
						/>
					</div>

					<Input
						type="textarea"
						label="Notes"
						name="notes"
						value={details.notes}
						error={detailsErr.notes}
						onChange={handleInput}
					/>
				</div>
			</div>
		</div>
	)
}

export default AccountDetails
