import { useState } from "react"
import { Tabs } from "../../../../../components/Tabs"
import { Table } from "../../../../../components/Table"
import { Input } from "../../../../../components/Input"
import { Button } from "../../../../../components/Button"
import useAxios from "../../../../../hooks/useAxios"
import toastMessage from "../../../../../utils/toastMessage"
import { RECONCILIATION_ROOT_API } from "../../../../../constants/api"

const Comments = ({ getData, entity, selected = {}, handleClose }) => {
  const [active, setActive] = useState()
  const [error, setError] = useState({})
  const [comment, setComment] = useState("")
  const [loading, setLoading] = useState(false)
  const axios = useAxios()

  function renderComments() {
    return (
      <div className="card">
        <div className="card-body">
          <Table
            showAdd
            addButtonText="Add"
            handleAddPressed={async () => (
              await setActive("add comment"), setActive(null)
            )}
            headers={[
              { title: "Comment", key: "comment" },
              { title: "Date", key: "dateCreated" },
            ]}
            data={selected.comments || []}
          />
        </div>
      </div>
    )
  }
  function renderAddComment() {
    const handleSubmit = async (e) => {
      if (!comment || comment === "") {
        setError({ comment: "Comment is required" })
        return
      }
      setLoading(true)
      const { err, res } = await axios({
        method: "PATCH",
        url: `${RECONCILIATION_ROOT_API}disputes/${selected.publicId}/actions`,
        params: { disputePublicId: selected.publicId },
        data: { actionType: "AddComment", comment },
        headers: null,
      })
      if (err) {
        setLoading(false)
        if (err.messages) {
          toastMessage("error", err.messages[0])
        }
        return
      }
      if (res) {
        setLoading(false)
        await setActive("comments")
        setTimeout(()=>setActive(), 500)
        getData()
        handleClose()
        toastMessage("success", "Comment added successfully")
      }
    }
    return (
      <div className="card">
        <div className="card-body">
          <Input
            label="Comment"
            error={error.comment}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            required
          />
        </div>
        <div className="card-footer d-flex justify-content-end gap-3">
          <Button
            onPress={handleClose}
            className="btn-secondary"
            text="Cancel"
          />
          <Button
            onPress={handleSubmit}
            isSubmitting={loading}
            className="btn-primary"
            text="Submit"
          />
        </div>
      </div>
    )
  }

  const options = [
    {
      title: "Comments",
      data: renderComments(),
    },
    {
      title: "Add Comment",
      data: renderAddComment(),
    },
  ]
  return <Tabs options={options} activeTab={active} />
}

export default Comments
