import { Table } from "../../../../../components/Table"

const BatchSettlement = ({ selected=[] }) => {
  const headers = [
    { title: "Vehicle", key: "vehicleNo" },
    { title: "Client", key: "paymentServiceAccount.name" },
    { title: "Account Type", key: "paymentServiceAccount.accountType" },
    { title: "Service No", key: "paymentServiceAccount.serviceNumber" },
    { title: "Payment Mode", key: "paymentMode" },
    { title: "Commission Fee", key: "commissionFees" },
    { title: "Amount", key: "totalAmount" },
    { title: "Confirmed Amount", key: "totalConfirmedAmount" },
    { title: "Created At", key: "dateCreated" },
    { title: "Modified At", key: "dateModified" },
  ]
  return (
    <div className="card">
      <div className="card-body">
        <Table headers={headers} data={selected} />
      </div>
    </div>
  )
}

export default BatchSettlement
