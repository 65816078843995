import { useEffect, useState } from "react"
import { Table } from "../../../../components/Table"
import useAxios from "../../../../hooks/useAxios"
import { RECONCILIATION_ROOT_API } from "../../../../constants/api"
import toastMessage from "../../../../utils/toastMessage"
import { Modal } from "../../../../components/Modal"
import Documents from "../Forms/Documents"

const Vehicles = ({ selected }) => {
	console.log({ selected })
	const [data, setData] = useState([])
	const [loading, setLoading] = useState(true)
	const [pagination, setPagination] = useState({})
	const [show, setShow] = useState(false)
	const [vehicle, setVehicle] = useState()

	const axios = useAxios()

	useEffect(() => {
		if (selected && selected.publicId) {
			getData()
		} else {
			setLoading(false)
		}
	}, [])

	const getData = async (params = {}) => {
		setLoading(true)
		const { err, res } = await axios({
			method: "GET",
			url: `${RECONCILIATION_ROOT_API}paymentServices/${selected.publicId}/vehicles`,
			params: {
				paymentServiceAccountPublicId: selected.publicId,
				...params,
			},
			headers: null,
		})
		if (err) {
			if (err.messages) {
				toastMessage("error", err.messages[0])
			}
			console.log({ err })
			setLoading(false)
			return
		}
		if (res) {
			setLoading(false)
			const { content, number, numberOfElements, totalElements, totalPages } =
				res
			const pagination = {
				pageNumber: number + 1,
				pageCount: numberOfElements,
				totalCount: totalElements,
				pageCount: totalPages,
			}
			setPagination(pagination)
			setData(content)
		}
	}
	const handlePagination = async (page) => {
		if (!page) return
		getData({ page: page - 1 })
	}

	const headers = [
		{ title: "Registration", key: "registrationNumber" },
		{ title: "Fare", key: "basicFare" },
		{ title: "Chassis Number", key: "chassisNumber" },
		{ title: "Model", key: "model" },
		{ title: "Short Code", key: "shortCode" },
		{ title: "Owner", key: "owner.name" },
		{ title: "Owner Phone", key: "owner.phoneNumber" },
		{ title: "Created At", key: "dateCreated" },
		{ title: "Actions", key: "action" },
	]
	return (
		<>
			<div className="card">
				<div className="card-body">
					<Table
						loading={loading}
						headers={headers}
						handlePagination={handlePagination}
						totalPageCount={pagination.pageCount}
						page={pagination.pageNumber}
						data={data}
						rowPress={async (row) => (await setVehicle(row), setShow(true))}
						actions={[
							{
								name: "Documents",
								onPress: async (row) => (await setVehicle(row), setShow(true)),
							},
						]}
					/>
				</div>
			</div>
			<Modal
				title="Vehicle Documents"
				show={show}
				size="lg"
				handleClose={() => (setVehicle(), setShow(false))}
			>
				<div className="card">
					<Documents selected={vehicle} />
				</div>
			</Modal>
		</>
	)
}

export default Vehicles
