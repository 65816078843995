import { Input } from "../../../../components/Input"

const ContactPerson = ({
	contactPerson = {},
	setContactPerson,
	contactPersonErr = {},
}) => {
	const handleInput = (e) => {
		const { name, value } = e.target
		if (contactPersonErr[name]) {
			delete contactPersonErr[name]
		}
		setContactPerson((prev) => ({
			...prev,
			[name]: value,
		}))
	}
	return (
		<div className="card-body ">
			<div className="d-flex justify-content-center">
				<div className="col-md-9 col-lg-6 row">
					<h3 className="mb-3">Personal Details</h3>
					<div className="col-md-6">
						<Input
							label="Full Name"
							required
							name="name"
							value={contactPerson.name}
							onChange={handleInput}
							error={contactPersonErr.name}
						/>
					</div>
					<div className="col-md-6">
						<Input
							label="National ID"
							required
							name="nationalId"
							value={contactPerson.nationalId}
							error={contactPersonErr.nationalId}
							onChange={handleInput}
						/>
					</div>
					<div className="col-md-6">
						<Input
							type="number"
							label="Phone Number"
							required
							name="phoneNumber"
							value={contactPerson.phoneNumber}
							error={contactPersonErr.phoneNumber}
							onChange={handleInput}
						/>
					</div>
					<div className="col-md-6">
						<Input
							required
							type="email"
							name="email"
							label="Email Address"
							value={contactPerson.email}
							error={contactPersonErr.email}
							onChange={handleInput}
						/>
					</div>
					{/* <h3 className="my-3">Credentials</h3>
					<div className="col-md-6">
						<Input
							type="number"
							label="Phone Number"
							required
							name="credentialPhoneNumber"
							value={contactPerson.credentialPhoneNumber}
							error={contactPersonErr.credentialPhoneNumber}
							onChange={handleInput}
						/>
					</div>
					<div className="col-md-6">
						<Input
							type="email"
							label="Email Address"
							required
							name="credentialEmail"
							value={contactPerson.credentialEmail}
							error={contactPersonErr.credentialEmail}
							onChange={handleInput}
						/>
					</div> */}
				</div>
			</div>
		</div>
	)
}

export default ContactPerson
