import "./style.css"

const SingleStepper = ({ currentStep, stepNo, children }) => {
	return (
		<div
			className="stepper"
			style={{
				color: currentStep === stepNo - 1
				? "#fff"
				: stepNo <= currentStep
				? "#fff"
				: "inherit",
				backgroundColor:
					currentStep === stepNo - 1
						? "#EF3D50"
						: stepNo <= currentStep
						? "#283a4c"
						: "#F4F7FC",
			}}
		>
			{children}
		</div>
	)
}

export default SingleStepper
