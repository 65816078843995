import { Button } from "../Button"
import SingleStepper from "./SingleStepper"
import "./style.css"

const Steppers = ({ children, currentStep, close }) => {
	return (
		<div className="card-body stepper-container">
			<div className="stepper-items">
				{children.map((child, i) => (
					<SingleStepper key={i} currentStep={currentStep} stepNo={i+1}>
						{child}
					</SingleStepper>
				))}
			</div>
			<div className="stepper-close">
				<Button onPress={close} className="btn-secondary" text="Cancel" />
			</div>
		</div>
	)
}

export default Steppers
