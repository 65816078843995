import { useEffect, useState } from "react"
import { Input } from "../../../../components/Input"
import useAxios from "../../../../hooks/useAxios"
import { LoadingSpinner } from "../../../../components/LoadingSpinner"
import { RECONCILIATION_ROOT_API } from "../../../../constants/api"

const BankDetails = ({ selected, bank = {}, setBank, bankErr = {},disabled }) => {
	const [loading, setLoading] = useState(true)

	const axios = useAxios()

	useEffect(() => {
		if (selected && selected.publicId) {
			getBankDetails()
		} else {
			setLoading(false)
		}
	}, [selected])

	async function getBankDetails() {
		// const { err, res, status } = await axios({
		// 	method: bank && bank.publicId ? "PUT" : "POST",
		// 	url: `${RECONCILIATION_ROOT_API}paymentServices/${selected.publicId}/bankDetails`,
		// 	headers: null,
		// 	data: bank,
		// })
		const { err, res } = await axios({
			method: "GET",
			url: `${RECONCILIATION_ROOT_API}paymentServices/${selected.publicId}/bankDetails`,
			headers: null,
			params:{
				paymentServiceAccountPublicId: selected.publicId
			}
		})

		if(err){
			setLoading(false)
		}
		if(res){
			setBank(res)
			setLoading(false)
		}
	}

	const handleInput = (e) => {
		const { name, value } = e.target
		if (bankErr[name]) {
			delete bankErr[name]
		}
		setBank((prev) => ({
			...prev,
			[name]: value,
		}))
	}

	return (
		<div className="card-body ">
			<div className="d-flex justify-content-center">
				<div className="col-md-9 col-lg-6 row">
					{loading ? (
						<LoadingSpinner />
					) : (
						<>
							<h3 className="mb-3">Bank Details</h3>
							<div className="col-md-6">
								<Input
									label="Bank Code"
									required
									name="bankCode"
									disabled={disabled}
									value={bank.bankCode}
									error={bankErr.bankCode}
									onChange={handleInput}
								/>
							</div>
							<div className="col-md-6">
								<Input
									label="Account Number"
									required
									disabled={disabled}
									name="accountNumber"
									value={bank.accountNumber}
									error={bankErr.accountNumber}
									onChange={handleInput}
								/>
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	)
}

export default BankDetails
