import { useState } from "react"
import { Input } from "../../../../../components/Input"
import { Button } from "../../../../../components/Button"
import useHandleFormValidate from "../../../../../hooks/useHandleFormValidate"
import useAxios from "../../../../../hooks/useAxios"
import { RECONCILIATION_ROOT_API } from "../../../../../constants/api"
import toastMessage from "../../../../../utils/toastMessage"

const Dispute = ({ entity={}, handleClose, getData }) => {
  const [dispute, setDispute] = useState({
    title: "",
    description: "",
  })
  const [error, setError] = useState({})
  const [loading, setLoading] = useState(false)
  const validateForm = useHandleFormValidate()
  const axios = useAxios()

  const handleInput = (e) => {
    const { name, value } = e.target
    if (error[name]) {
      delete error[name]
    }
    setDispute((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleSubmit = async () => {
    const { error, valid } = await validateForm(dispute)
    setError(error)
    if (!valid) return
    setLoading(true)
    const { err, res } = await axios({
      method: "POST",
      url: `${RECONCILIATION_ROOT_API}disputes`,
      headers: null,
      data: { ...dispute, entityPublicId: entity.publicId },
    })
    if (err) {
      if (err.messages) {
        toastMessage("error", err.messages[0])
      }
      setLoading(false)
      return
    }
    if (res) {
      getData()
      setLoading(false)
      toastMessage("success", "A new dispute has been raised sucessfully.")
      handleClose()
    }
  }
  return (
    <div className="card">
      <div className="card-body">
        <Input
          label="Title"
          required
          name="title"
          value={dispute.title}
          error={error.title}
          onChange={handleInput}
        />
        <Input
          label="Description"
          required
          name="description"
          value={dispute.description}
          error={error.description}
          onChange={handleInput}
        />
      </div>
      <div className="card-footer d-flex justify-content-end gap-3">
        <Button onPress={handleClose} className="btn-secondary" text="Cancel" />
        <Button
          onPress={handleSubmit}
          isSubmitting={loading}
          className="btn-primary"
          text="Submit"
        />
      </div>
    </div>
  )
}

export default Dispute
