import { useEffect, useState } from "react"
import { Input, Select } from "../../../../components/Input"
import { Button } from "../../../../components/Button"
import useAxios from "../../../../hooks/useAxios"
import useHandleFormValidate from "../../../../hooks/useHandleFormValidate"
import { RECONCILIATION_ROOT_API } from "../../../../constants/api"
import toastMessage from "../../../../utils/toastMessage"

const BankAccount = ({
	getData,
	selected,
	handleClose,
	account = {},
	accountError = {},
	setAccount,
}) => {
	const [submitting, setSubmitting] = useState(false)

	const [acc, setAcc] = useState(account)
	const [error, setError] = useState(accountError)

	const axios = useAxios()
	const validate = useHandleFormValidate()

	useEffect(() => {
		if (selected) {
			let account = { ...selected }
			account.bankAccountStatus = {
				label: selected.bankAccountStatus,
				value: selected.bankAccountStatus,
			}
			account.bankCode = {
				label: selected.bankCode,
				value: selected.bankCode,
			}

			setAcc(account)
		}
	}, [])

	const handleInput = (e) => {
		const { name, value } = e.target
		if ((accountError && accountError[name]) || (error && error[name])) {
			delete accountError[name]
		}
		if (setAccount) {
			setAccount((prev) => ({
				...prev,
				[name]: value,
			}))
		} else {
			setAcc((prev) => ({
				...prev,
				[name]: value,
			}))
		}
	}

	const handleSelect = (value, { name }) => {
		if ((accountError && accountError[name]) || (error && error[name])) {
			delete accountError[name]
		}
		if (setAccount) {
			setAccount((prev) => ({
				...prev,
				[name]: value,
			}))
		} else {
			setAcc((prev) => ({
				...prev,
				[name]: value,
			}))
		}
	}

	const handleSubmit = async (e) => {
		e && e.preventDefault()
		const { error, valid } = await validate(account)
		if (error) setError(error)
		if (!valid) return

		setSubmitting(true)

		const data = {
			publicId: acc.publicId,
			bankAccountName: acc.bankAccountName,
			bankName: acc.bankName,
			bankCode: acc.bankCode && acc.bankCode.value,
			bankAccountNo: acc.bankAccountNo,
			bankAccountBalance: acc.bankAccountBalance&&Number(acc.bankAccountBalance),
			bankAccountStatus:
				acc.bankAccountStatus && acc.bankAccountStatus.value,
		}
		console.log("Update account: ", data)
		const { err, res, status } = await axios({
			method: "PUT",
			url: `${RECONCILIATION_ROOT_API}bankAccounts`,
			headers: null, //contactPerson
			data,
		})

		if (err) {
			if (err.messages) {
				err.messages.forEach((err) => {
					toastMessage("error", err)
				})
			} else {
				toastMessage("error", err)
			}
			setSubmitting(false)
		}
		if (res || (status && status === 200)) {
			toastMessage(
				"success",
				`Account details updated successfully.`
			)
			handleClose()
			getData()
			setSubmitting(false)
		}
	}

	return (
		<div className={`${selected && "card"}`}>
			<div className="card-body">
				<div className="d-flex justify-content-center">
					<div className={`row ${!selected && "col-md-7 col-lg-4"}`}>
						<h3 className="mb-3">Bank Details</h3>
						<Input
							label="Account Name"
							required
							name="bankAccountName"
							value={acc.bankAccountName}
							onChange={handleInput}
							error={error.bankAccountName}
						/>
						<Input
							label="Account Number"
							required
							name="bankAccountNo"
							value={acc.bankAccountNo}
							onChange={handleInput}
							error={error.bankAccountNo}
						/>
						<div className="col-md-6">
							<Input
								label="Account Balance"
								required
								name="bankAccountBalance"
								value={acc.bankAccountBalance}
								onChange={handleInput}
								error={error.bankAccountBalance}
							/>
						</div>
						<div className="col-md-6">
							<Select
								label="Account Status"
								name="bankAccountStatus"
								value={acc.bankAccountStatus}
								options={STATUS}
								onChange={handleSelect}
							/>
						</div>
						<div className="col-md-6">
							<Input
								label="Bank Name"
								required
								name="bankName"
								value={acc.bankName}
								onChange={handleInput}
								error={error.bankName}
							/>
						</div>
						<div className="col-md-6">
							<Select
								required
								label="Bank Code"
								name="bankCode"
								value={acc.bankCode}
								options={CODES}
								onChange={handleSelect}
							/>
						</div>
					</div>
				</div>
			</div>
			{selected && (
				<div className="card-footer d-flex justify-content-end gap-3">
					<Button
						text="Cancel"
						onPress={handleClose}
						className="btn-secondary"
					/>
					<Button
						text="Update"
						onPress={handleSubmit}
						className="btn-primary"
						isSubmitting={submitting}
					/>
				</div>
			)}
		</div>
	)
}

export default BankAccount

const STATUS = [
	{ label: "Enable", value: "Enable" },
	{ label: "Disable", value: "Disable" },
	{ label: "Active", value: "Active" },
	{ label: "Suspended", value: "Suspended" },
	//Enable, Disable, Active, Suspended
]

const CODES = [
	//[ 1, 2, 3, 7, 10, 11, 12, 16, 17, 18, 19, 23, 25, 31, 35, 41, 49, 50, 51, 53, 54, 55, 57, 61, 63, 66, 68, 70, 72, 74, 78 ]
	{ label: "1", value: "1" },
	{ label: "2", value: "2" },
	{ label: "3", value: "3" },
	{ label: "7", value: "7" },
	{ label: "10", value: "10" },
	{ label: "11", value: "11" },
	{ label: "12", value: "12" },
	{ label: "16", value: "16" },
	{ label: "17", value: "17" },
	{ label: "18", value: "18" },
	{ label: "19", value: "19" },
	{ label: "23", value: "23" },
	{ label: "25", value: "25" },
	{ label: "31", value: "31" },
	{ label: "35", value: "35" },
	{ label: "41", value: "41" },
	{ label: "49", value: "49" },
	{ label: "50", value: "50" },
	{ label: "51", value: "51" },
	{ label: "52", value: "52" },
	{ label: "53", value: "53" },
	{ label: "54", value: "54" },
	{ label: "55", value: "55" },
	{ label: "57", value: "57" },
	{ label: "61", value: "61" },
	{ label: "63", value: "63" },
	{ label: "66", value: "66" },
	{ label: "68", value: "68" },
	{ label: "70", value: "70" },
	{ label: "72", value: "72" },
	{ label: "74", value: "74" },
	{ label: "78", value: "78" },
]
