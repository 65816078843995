// Icons // GET ICON FROM https://boxicons.com/
export const sidebarMenus = [
	{
		group: "Home",
		route: "/dashboard/home",
		icon: "bx bx-home",
	},
	{
		group: "Service Accounts",
		icon: "bx-user-pin",
		route: "/dashboard/service_accounts",
	},
	{
		group: "Bank Accounts",
		icon: "bxs-bank",
		access: [{}],
		items: [
			{
				name: "Accounts Details",
				access: [{}],
				group: "Bank Accounts",
				icon: "bx-link-alt",
			},
			{
				name: "Contact Persons",
				access: [{}],
				group: "Bank Accounts",
				icon: "bx-link-alt",
			},
		],
	},
	{
		group: "Reconciliation",
		icon: "bxs-home",
		access: [{}],
		items: [
			{
				name: "Cashless",
				access: [{}],
				group: "Reconciliation",
				icon: "bx-link-alt",
			},
			{
				name: "Mapato",
				access: [{}],
				group: "HReconciliation",
				icon: "bx-link-alt",
			},
			{
				name: "Pesalink",
				access: [{}],
				group: "Reconciliation",
				icon: "bx-link-alt",
			},
		],
		show: true,
	},
	{
		group: "Reports",
		icon: "bxs-report",
		access: [{}],
		items: [
			{
				name: "Analytics",
				access: [{}],
				group: "Reports",
				icon: "bx-link-alt",
			},
			{
				name: "Report Name",
				access: [{}],
				group: "Reports",
				icon: "bx-link-alt",
			},
		],
		show: true,
	},
	{
		group: "Settings",
		route: "/dashboard/settings",
		icon: "bx-cog",
	},
]
