import React from "react";
import "./styles.css";
import DIL_LOGO from "../../assets/logo.png";
import MOBITILL_LOGO from "../../assets/mobitill_logo.png";

const Splash = () => {
  return (
    <div className="splash-container">
      <div className="splash-logo">
        <img src={MOBITILL_LOGO} />
      </div>
    </div>
  );
};

export default Splash;
