import React from "react"
import "./styles.css"
import { Button } from "../Button"
import Input from "../Input/Input"
import axios from "axios"
import toastMessage from "../../utils/toastMessage"
import { MAIN_DASHBOARD_ROOT_API } from "../../constants/api"
import { setStorage } from "../../utils/storage"
import LOGO from "../../assets/logo.png"
import MOBITILL_LOGO from "../../assets/mobitill_logo.png";


class Login extends React.Component {
	state = {
		email: "",
		password: "",
		isSubmitting: false,
		error: {},
		password_checker: {},
	}

	onChangeText(name, e) {
		let { error } = this.state,
			text = e.target.value

		delete error[name]

		this.setState({
			[name]: text,
			error,
		})
	}

	validateForm() {
		let { email, password, error } = this.state

		if (email === "") {
			error.email = "Email address is required"
		}

		if (password === "") {
			error.password = "Password is required"
		}

		this.setState({
			error,
		})
	}

	onSubmit = async (e) => {
		e.preventDefault()
		await this.validateForm()

		let { email, password, error } = this.state

		if (Object.keys(error).length === 0) {
			this.setState({ isSubmitting: true })

			let url = "/login",
				data = { user: email, password }

			const options = {
				method: "POST",
				url: `${MAIN_DASHBOARD_ROOT_API}${url}`,
				data: { data },
			}

			axios(options)
				.then((data) => {
					this.onSuccess(data.data)
				})
				.catch((error) => {
					toastMessage("error", error)

					this.setState({
						isSubmitting: false,
					})
				})
		}
	}

	onSuccess = async (user) => {
		//sve storage
		await setStorage({
			token: user.token,
			username: user.data.firstName + " " + user.data.lastName,
			role: user.data.role,
			user_id: user.data._id,
			phone: user.data.phone,
			allowedItems: user.data.allowedItems,
		})

		this.setState({
			isSubmitting: false,
		})

		window.location.href = "/dashboard/home"
		// window.location.href = "/dashboard/reconciliation/cashless";
	}

	render() {
		return (
			<div className="center-both-form">
				<div className="container">
					<div className="row align-items-center justify-content-center gap-5">
						<div className="col-12 col-md-6 col-lg-5 col-xl-4">
							<div className="form-container card form-card">
								<center className="dil-logo-container">
									<img src={MOBITILL_LOGO} />
									<div className="form-header-description">
										<h2>Welcome to MobiTill</h2>
										<p>Please add the following details to login</p>
									</div>
								</center>
								<div className="card-body">
									<form>
										<Input
											label="Email Address"
											placeholder="Enter Email address"
											value={this.state.email}
											onChange={(e) => this.onChangeText("email", e)}
											error={this.state.error.email}
											className="form-control-lg"
										/>
										<Input
											label="Password"
											placeholder="Enter Password"
											type="password"
											value={this.state.password}
											onChange={(e) => this.onChangeText("password", e)}
											error={this.state.error.password}
											className="form-control-lg"
											password_checker={this.state.password_checker}
										/>
										<Button
											text="Login"
											className="btn-lg btn-primary w-100"
											isSubmitting={this.state.isSubmitting}
											onPress={this.onSubmit.bind(this)}
										/>
									</form>
								</div>
								<center className="card-footer">
									<a className="forgot-password" href="/forgot_password">
										<b>Forgot Password?</b>
									</a>
								</center>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Login
