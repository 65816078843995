import React, { useEffect, useState } from "react"
import { Table } from "../../../components/Table"
import { useLocation, useNavigate } from "react-router-dom"
import { RECONCILIATION_ROOT_API } from "../../../constants/api"
import useAxios from "../../../hooks/useAxios"
import toastMessage from "../../../utils/toastMessage"

const BusOwners = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const axios = useAxios()

	const [loading, setLoading] = useState(true)
	const [data, setData] = useState([])
	const [pagination, setPagination] = useState({})
	const [sacco, setSacco] = useState(location.state && location.state.sacco)

	useEffect(() => {
		if (sacco && sacco.publicId) {
			getData()
		} else {
			setLoading(false)
		}
	}, [location])

	const getData = async (params = {}) => {
		setLoading(true)
		const { err, res } = await axios({
			method: "GET",
			url: `${RECONCILIATION_ROOT_API}paymentServices`,
			params: {
				...params,
				// accountType: "BusOwner",
				saccoPaymentServiceAccountPublicId: sacco.publicId,
			},
			headers: null,
		})
		if (err) {
			if (err.messages) {
				toastMessage("error", err.messages[0])
			}
			setLoading(false)
			return
		}
		if (res) {
			console.log({ res })

			setLoading(false)
			const { content, number, numberOfElements, totalElements, totalPages } =
				res
			const pagination = {
				pageNumber: number + 1,
				pageCount: numberOfElements,
				totalCount: totalElements,
				pageCount: totalPages,
			}
			setPagination(pagination)
			setData(content)
		}
	}
	const handleRowPress = (row) => {
		navigate(
			`/dashboard/sales/service_accounts/view/${row.publicId}/${row.name}`
		)
	}
	const handleEdit = (row) => {
		navigate(`/dashboard/sales/service_accounts/add`, {
			state: {
				selectedOwner: row,
				tab: "bus owner",
			},
		})
	}
	const handlePagination = async (page) => {
		if (!page) return
		getData({ page: page - 1 })
	}

	const headers = [
		{ title: "Service Number", key: "serviceNumber" },
		{ title: "Name", key: "name" },
		{ title: "Account Type", key: "accountType" },
		{ title: "Status", key: "status" },
		{ title: "KRA Pin", key: "kraPin" },
		{ title: "Date Created", key: "dateCreated" },
		{ title: "Actions", key: "action" },
	]
	return (
		<div className="card">
			<div className="card-body">
				<Table
					isLoading={loading}
					headers={headers}
					showAdd
					totalPageCount={pagination.pageCount}
					page={pagination.pageNumber}
					handlePagination={handlePagination}
					rowPress={handleRowPress}
					addIcon="bx bx-arrow-back"
					addButtonText="Back to Service Accounts"
					handleAddPressed={() => navigate("../", { replace: true })}
					data={data}
					actions={[
						{
							name: "View",
							onPress: (row) => handleRowPress(row),
              route:""
						},
						{
							name: "Edit",
							onPress: (row) => handleEdit(row),
              route:""
						},
					]}
				/>
			</div>
		</div>
	)
}

export default BusOwners
