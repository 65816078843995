import { useState } from "react"
import useAxios from "../../../../hooks/useAxios"
import { useNavigate } from "react-router-dom"
import { Table } from "../../../../components/Table"

const ViewAll = () => {
	const [data, setData] = useState([])
	const [pagination, setPagination] = useState({})
	const [loading, setLoading] = useState(true)

	const [showAdd, setShowAdd] = useState(false)
	const axios = useAxios()
	const navigate = useNavigate()

	const getData = async (params = {}) => {}

	const handlePagination = async (page) => {
		if (!page) return
		getData({ page: page - 1 })
	}

    const headers = [
        { title: "RUN", key: "runNumber" },
    ]
	return (
		<>
			<div class="card text-start">
				<div class="card-body">
                    Uploaded documents here
					{/* <Table
						isLoading={loading}
						data={data}
						showAdd
						addButtonText="Reconcile"
						handlePagination={handlePagination}
						totalPageCount={pagination.pageCount}
						page={pagination.pageNumber}
						handleAddPressed={() => setShowAdd(true)}
						headers={headers}
					/> */}
				</div>
			</div>
		</>
	)
}

export default ViewAll
