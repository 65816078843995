import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import useAxios from "../../../../hooks/useAxios"
import { Table } from "../../../../components/Table"
import toastMessage from "../../../../utils/toastMessage"
import { RECONCILIATION_ROOT_API } from "../../../../constants/api"
import { Modal } from "../../../../components/Modal"
import ContactPerson from "../AccountForms/ContactPerson"
import BankAccount from "../AccountForms/BankAccount"

const ViewAll = ({ contact }) => {
	const [data, setData] = useState([])
	const [pagination, setPagination] = useState({})
	const [loading, setLoading] = useState(true)
	const [contactPerson, setContactPerson] = useState(contact)
	const [showContactPerson, setShowContactPerson] = useState(false)
	const [showEdit, setShowEdit] = useState(false)
	const [selected, setSelected] = useState()

	const axios = useAxios()
	const navigate = useNavigate()

	useEffect(() => {
		getData()
	}, [])

	const getData = async (params = {}) => {
		setLoading(true)
		const { err, res } = await axios({
			method: "GET",
			url: `${RECONCILIATION_ROOT_API}bankAccounts`,
			params: {
				contactPersonPublicId: contactPerson && contactPerson.publicId,
				...params,
			},
			headers: null,
		})
		if (err) {
			if (err.messages) {
				toastMessage("error", err.messages[0])
			}
			setLoading(false)
			return
		}
		if (res) {
			setLoading(false)

			const { content, number, numberOfElements, totalElements, totalPages } =
				res
			const pagination = {
				pageNumber: number + 1,
				pageCount: numberOfElements,
				totalCount: totalElements,
				pageCount: totalPages,
			}
			setPagination(pagination)
			setData(content)
		}
	}

	const handlePagination = async (page) => {
		if (!page) return
		getData({ page: page - 1 })
	}

	const headers = [
		{ title: "Account Number", key: "bankAccountNo" },
		{ title: "Account Name", key: "bankAccountName" },
		{ title: "Account Balance", key: "bankAccountBalance" },
		{ title: "Bank Name", key: "bankName" },
		{ title: "Bank Code", key: "bankCode" },
		{ title: "Account Status", key: "bankAccountStatus" },
		{ title: "Date Created", key: "dateCreated" },
		{ title: "Actions", key: "action" },
	]
	return (
		<>
			<div class="card text-start">
				<div class="card-body">
					<Table
						isLoading={loading}
						data={data}
						showAdd
						addButtonText="Bank Account"
						handlePagination={handlePagination}
						totalPageCount={pagination.pageCount}
						page={pagination.pageNumber}
						handleAddPressed={() => navigate("add")}
						headers={headers}
						actions={[
							{
								name: "Edit",
								onPress: async (row) => (
									await setSelected(row), setShowEdit(true)
								),
							},
							{
								name: "Documents",
								onPress: (row) =>
									navigate(`documents/${row.bankAccountName}`, {
										state: { selected: row },
									}),
							},
							{
								name: "Contact Person",
								onPress: async (row) => (
									await setContactPerson(row.bankAccountContactPerson),
									setShowContactPerson(true)
								),
							},
						]}
					/>
				</div>
			</div>

			<Modal
				show={showEdit}
				title="Update Bank Account"
				handleClose={() => (setSelected(), setShowEdit(false))}
			>
				<BankAccount
					getData={getData}
					selected={selected}
					handleClose={() => (setSelected(), setShowEdit(false))}
				/>
			</Modal>

			<Modal
				show={showContactPerson}
				title="Contact Person"
				handleClose={() => (setContactPerson(), setShowContactPerson(false))}
			>
				<ContactPerson
					getData={getData}
					selected={contactPerson}
					handleClose={() => (setContactPerson(), setShowContactPerson(false))}
				/>
			</Modal>
		</>
	)
}

export default ViewAll
