import React from "react";
import "./styles.css";
import { Button } from "../Button";
import { Input } from "../Input";
import { MAIN_DASHBOARD_ROOT_API } from "../../constants/api";
import axios from "axios";
import toastMessage from "../../utils/toastMessage";
import LOGO from "../../assets/logo.png";

class ForgotPassword extends React.Component {
  state = {
    email: "",
    error: {},
    isSubmitting: false,
  };

  onChangeText(name, e) {
    let { error } = this.state;
    delete error[name];
    this.setState({
      error,
      [name]: e.target.value,
    });
  }

  validateForm() {
    let { email, error } = this.state;

    if (email === "") {
      error.email = "Email address or phone number is required";
    }

    this.setState({
      error,
    });
  }

  onSubmit = async () => {
    await this.validateForm();

    const { error, email } = this.state;

    if (Object.keys(error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      const options = {
        method: "POST",
        url: MAIN_DASHBOARD_ROOT_API + "/forgot_password_user",
        data: {
          email: email,
        },
      };

      axios(options)
        .then((res) => {
          this.setState({
            isSubmitting: false,
          });

          window.location.href = "/reset_password/" + email;
        })
        .catch((error) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage("error", error);
        });
    }
  };

  render() {
    return (
      <div className="center-both-form">
        <div className="container">
          <div className="row align-items-center justify-content-center gap-5">
            <div className="col-12 col-md-4">
              <div className="form-container card form-card">
                <div className="card-body">
                  <center className="dil-logo-container">
                    <img src={LOGO} />
                    <h2>Forgot Password?</h2>
                    <p className="text-disabled mb-4">
                      No worries, we'll send you reset instructions
                    </p>
                  </center>
                  <form>
                    <Input
                      label="Email address"
                      placeholder="Enter Email address"
                      value={this.state.email}
                      onChange={(e) => this.onChangeText("email", e)}
                      className="form-control-lg mb-4"
                      required
                    />
                    <Button
                      text="Submit"
                      onPress={this.onSubmit.bind(this)}
                      className="btn-lg btn-primary w-100"
                      isSubmitting={this.state.isSubmitting}
                    />
                  </form>
                </div>
                <center className="card-footer">
                  <a href="/login">
                    <b>Go to login</b>
                  </a>
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ForgotPassword;
