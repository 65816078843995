import { useEffect, useState } from "react"
import { Table } from "../../../../../components/Table"
import useAxios from "../../../../../hooks/useAxios"
import { RECONCILIATION_ROOT_API } from "../../../../../constants/api"
import { Modal } from "../../../../../components/Modal"
import BatchSettlement from "./BatchSettlement"
import Approve from "./Approve"

const BatchSettlements = ({ reconciliationPublicId }) => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [pagination, setPagination] = useState({})
  const [selected, setSelected] = useState({})
  const [show, setShow] = useState(false)
  const [approve, setApprove] = useState(false)
  const axios = useAxios()

  useEffect(() => {
    if (reconciliationPublicId) {
      getData()
    } else {
      setLoading(false)
    }
  }, [])

  const getData = async (params = {}) => {
    const { err, res } = await axios({
      method: "GET",
      url: `${RECONCILIATION_ROOT_API}reconciliations/${reconciliationPublicId}/settlementBatch`,
      params: {
        reconciliationPublicId,
        ...params
      },
      headers: null,
    })
    if (err) {
      setLoading(false)
      return
    }
    if (res) {
      setLoading(false)
      const { content, number, numberOfElements, totalElements, totalPages } =
        res
      setData(content)
      const pagination = {
				pageNumber: number + 1,
				pageCount: numberOfElements,
				totalCount: totalElements,
				pageCount: totalPages,
			}
			setPagination(pagination)
    }
  }

  const handlePagination = async (page) => {
		if (!page) return
		getData({ page: page - 1 })
	}

  const headers = [
    // { title: "Select", key: "selectAll", onSelect: handleSelect },
    { title: "Batch Number", key: "batchNumber" },
    { title: "Status", key: "status" },
    { title: "Total Payment Amount", key: "totalPaymentAmount" },
    { title: "Total Settlement Amount", key: "totalSettlementAmount" },
    { title: "Total Commission Amount", key: "totalCommissionAmount" },
    { title: "Created At", key: "dateCreated" },
    { title: "Modified At", key: "dateModified" },
    { title: "Actions", key: "action" },
  ]
  return (
    <>
      <div className="card">
        <div className="card-body">
          <Table
            rowPress={(item) => (setSelected(item), setShow(true))}
            data={data}
            headers={headers}
            handlePagination={handlePagination}
						totalPageCount={pagination.pageCount}
						page={pagination.pageNumber}
            isLoading={loading}
            actions={[
              {
                name: "Approve",
                onPress: (item) => (setSelected(item), setApprove(true)),
              },
              {
                name: "Settlements",
                onPress: (item) => (setSelected(item), setShow(true)),
              },
            ]}
          />
        </div>
      </div>
      <Modal
        show={show}
        title="Batch Settlements"
        size="lg"
        handleClose={() => (setSelected({}), setShow(false))}
      >
        <BatchSettlement
          selected={selected.settlements}
          handleClose={() => (setSelected({}), setShow(false))}
        />
      </Modal>

      <Modal
        show={approve}
        title="Approve Batch Settlement"
        handleClose={() => (setSelected({}), setApprove(false))}
      >
        <Approve
          selected={selected}
          reconciliationPublicId={reconciliationPublicId}
          handleClose={() => (setSelected({}), setApprove(false))}
        />
      </Modal>
    </>
  )
}

export default BatchSettlements
