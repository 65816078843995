import { useEffect, useState } from "react"
import { Table } from "../../../../components/Table"
import { Modal } from "../../../../components/Modal"
import Document from "./Document"
import useAxios from "../../../../hooks/useAxios"
import { RECONCILIATION_ROOT_API } from "../../../../constants/api"
import toastMessage from "../../../../utils/toastMessage"

const Documents = ({ selected }) => {
	const [data, setData] = useState([])
	const [modal, setModal] = useState(false)
	const [loading, setLoading] = useState(true)
	const [pagination, setPagination] = useState({})
	const axios = useAxios()

	useEffect(() => {
		if (selected && selected.publicId) {
			getData()
		} else {
			setLoading(false)
		}
	}, [selected])

	const getData = async (params = {}) => {
		setLoading(true)
		const { err, res } = await axios({
			method: "GET",
			url: `${RECONCILIATION_ROOT_API}files/list`,
			headers: null,
			params: {
				referenceId: selected.publicId,
				...params,
			},
		})
		if (err) {
			if (err.messages) {
				toastMessage("error", err.messages[0])
			}
			console.log({ err })
			setLoading(false)
			return
		}
		if (res) {
			setLoading(false)
			const { content, number, numberOfElements, totalElements, totalPages } =
				res
			const pagination = {
				pageNumber: number + 1,
				pageCount: numberOfElements,
				totalCount: totalElements,
				pageCount: totalPages,
			}
			setPagination(pagination)
			setData(content)
		}
	}

	const handlePagination = async (page) => {
		if (!page) return
		getData({ page: page - 1 })
	}

	const headers = [
		{ title: "Document Name", key: "documentName" },
		{ title: "Verification Status", key: "verificationStatus" },
		{ title: "Content Type", key: "contentType" },
		{ title: "Date Uploaded", key: "dateCreated" },
	]
	return (
		<>
			<div className="card-body">
				<Table
					isLoading={loading}
					showAdd
					handlePagination={handlePagination}
					totalPageCount={pagination.pageCount}
					page={pagination.pageNumber}
					addButtonText="Upload Document"
					headers={headers}
					handleAddPressed={() => setModal(true)}
					data={data}
				/>
			</div>
			<Modal
				show={modal}
				title="Upload Document"
				handleClose={() => setModal(false)}
			>
				<Document
					getData={getData}
					handleClose={() => setModal(false)}
					referenceId={selected && selected.publicId}
				/>
			</Modal>
		</>
	)
}

export default Documents
